import React from "react";
import { Box, Typography, TextField, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { useMutation } from '@tanstack/react-query'
import { changePassword } from '../../Services/DataService';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useState } from "react";
import { ErrorElement } from '../../Models/dto/ErrorElement'
import { useNavigate } from "react-router-dom";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress } from "@mui/material";

const ChangePassword = () => {
    const navigate = useNavigate();
    const [postError, setError] = useState<ErrorElement>()

    const schema = yup.object({
        newPassword: yup.string()
            .required("New password is required")
            .matches(
                /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
                "Het wachtwoord moet minimaal 8 tekens lang zijn en een cijfer, een kleine letter en een hoofdletter bevatten."
            ),
        confirmNewPassword: yup.string()
            .oneOf([yup.ref('newPassword')], 'Passwords must match')
            .required("Please confirm your new password")
    });


    const onSubmit = async (data: any) => {
        const toSubmit = {
            newPassword: data.newPassword
        }
        mutate(toSubmit);
    };

    const { handleSubmit, register, reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema)

    });

    const { mutate, isLoading } = useMutation(
        changePassword
        , {
            onSuccess: (data) => {
                reset();
                navigate("/")
            },
            onError: (error: any) => {
                error.response.status == 403 ? navigate('/login') : setError(error.response.data);
            }
        });

    if (isLoading) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '80vh'
                }}
            >
                <CircularProgress />
            </div>
        );
    }

    return (
        <Box
            sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Typography component="h1" variant="h5">
                Wijzig wachtwoord
            </Typography>
            <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 2 }}>
                <TextField
                    required
                    autoFocus
                    sx={{ mb: 1 }}
                    fullWidth
                    label="Nieuw wachtwoord"
                    autoComplete="off"
                    type="password"
                    {...register("newPassword")}
                />
                <TextField
                    required
                    fullWidth
                    label="Bevestig nieuw wachtwoord"
                    autoComplete="off"
                    type="password"
                    {...register("confirmNewPassword")}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 2 }}
                >
                    Wijzig wachtwoord
                </Button>
                {postError &&
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{ display: 'flex', textAlign: "left", alignItems: 'center', width: "25vw" }}>
                            <ErrorOutlineIcon style={{ fill: '#FF2D00' }} />
                            <p style={{ marginLeft: '2px' }}>{postError.errorCode}</p>
                        </div>
                    </div>
                }
                {Object.values(errors).some((error) => error) && (
                    <>
                        {Object.entries(errors).map(([key, value]) => (
                            value && (
                                <div key={key} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <div style={{ display: 'flex', textAlign: "left", alignItems: 'center', width: "25vw" }}>
                                        <ErrorOutlineIcon style={{ fill: '#FF2D00' }} />
                                        <p style={{ marginLeft: '2px' }}>{value?.message}</p>
                                    </div>
                                </div>
                            )
                        ))}
                    </>
                )}
            </Box>
        </Box>
    );
};

export default ChangePassword;
