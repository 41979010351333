import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { createAbsence, getAllEmployees } from '../Services/DataService';
import { useQuery } from '@tanstack/react-query'
import { CoachEmployee } from '../Models/CoachEmployee';
import { FormControl, InputLabel, MenuItem, Select, Switch, TextField } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { CircularProgress } from '@mui/material';
import { useForm } from "react-hook-form";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs'; // Import dayjs
import { useNavigate } from "react-router-dom";

const style = {
    width: "50vw",
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

export default function NewAbsence() {
    const [employee, setEmployee] = React.useState('');
    const [error, setError] = React.useState<boolean>(false);
    const querycl = useQueryClient();
    const [selectedFromDate, setSelectedFromDate] = React.useState(dayjs().format('DD-MM-YYYY'));
    const [selectedToDate, setSelectedToDate] = React.useState(dayjs().endOf('week').format('DD-MM-YYYY'));
    const navigate = useNavigate();

    const { handleSubmit, register, reset, formState: { errors } } = useForm({
    });

    const handleFromDateChange = (newValue:any) => {
        setSelectedFromDate(newValue ? newValue.format('DD-MM-YYYY') : null);
    };

    const handleToDateChange = (newValue:any) => {
        setSelectedToDate(newValue ? newValue.format('DD-MM-YYYY') : null);
    };

    const handleChangeEmployee = (event: SelectChangeEvent) => {
        setEmployee(event.target.value as string);
    };

    const { isLoading: employeesLoading, data: employees } = useQuery<CoachEmployee[]>(
        ['allEmployees'],
        () => getAllEmployees(), {
        staleTime: 300000,
        retry: false
    }
    );

    const onSubmit = (data:any) => {
        const toSubmit = {
            person: data.employee,
            fromDate: selectedFromDate.toString(),
            untilDate: selectedToDate.toString(),
            reason: data.reason
        }
        mutate(toSubmit);
    };

    const { mutate, isLoading } = useMutation(
        (newAbsence: any) => createAbsence(newAbsence)
        , {
            onSuccess: () => {
                reset();
                setEmployee('');
                querycl.removeQueries(["absences"]);
            },
            onError: (error: any) => {
                error.response.status == 403 ? navigate('/login') : setError(true);
            }
        });

    if (employeesLoading || isLoading) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '80vh'
                }}
            >
                <CircularProgress />
            </div>
        );
    }

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={style}>
                    <FormControl fullWidth>

                        <InputLabel>Werknemer</InputLabel>
                        <Select
                            value={employee}
                            label="Doelgroepmedewerker"
                            error={Boolean(errors.employee)}
                            {...register("employee")}
                            onChange={handleChangeEmployee}
                        >
                            {employees?.map((employee) => {
                                return <MenuItem value={employee.personId} key={employee.personId}>{employee.firstName}</MenuItem>

                            })}
                        </Select>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nl-be" >
                            <DatePicker onChange={handleFromDateChange} label={"Vanaf"} sx={{ marginTop: "2vh" }} />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nl-be">
                            <DatePicker onChange={handleToDateChange} label={"Tot"} sx={{ marginTop: "2vh" }} />
                        </LocalizationProvider>
                        <TextField
                            {...register("reason")}
                            label="Reden"
                            type="text"
                            sx={{ marginTop: "2vh", marginBottom: "2vh" }}
                            {...register("reason")}
                        />

                        <Button variant="contained" color="primary" type='submit'>Opslaan</Button>
                    </FormControl>
                </Box>
            </form>
        </div>
    );
}
