import { CreateContactInformationDto } from "./CreateContactInformationDto";

export class CreateContactPersonDto {
    personId: string;
    firstName: string;
    lastName: string;
    contactInformation: CreateContactInformationDto;
    constructor(
        personId: string,
        firstName: string,
        lastName: string,
        contactInformation: CreateContactInformationDto,
    ) {
        this.personId = personId;
        this.firstName = firstName;
        this.lastName = lastName;
        this.contactInformation = contactInformation;
    }
    toJson() {
        const json: any = {
            firstName: this.firstName,
            lastName: this.lastName,
            contactInformation: this.contactInformation.toJson(),
        };

        if (this.personId !== "") {
            json.personId = this.personId;
        }

        return json;
    }

}