import { configureStore } from "@reduxjs/toolkit";
import employeePlanning from "./EmployeePlanning";
import coachPlanning from "./CoachPlanning";
import authAuth from "./Auth";

export const store = configureStore({
    reducer: {
        employeePlanning: employeePlanning,
        coachPlanning: coachPlanning,
        Auth: authAuth,
    }
});

export type RootState = ReturnType<typeof store.getState>;
