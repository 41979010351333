import React from 'react';
import { useQueryClient, useQuery } from '@tanstack/react-query'
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, TypedUseSelectorHook, useDispatch } from "react-redux";
import { RootState } from "../../Reducers/ReduxStore";
import { Grid, Typography, Button, CircularProgress } from '@mui/material';
import TimetableCard from '../../Components/Timetable/TimetableCard';
const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
import dayjs from 'dayjs';
import { createEmployeePlanning, createCoachPlanning, getAssignableAndNonAssignableCoachesForThisWeek, getAssignableAndNonAssignableEmployeesForThisWeek } from '../../Services/DataService';
import { selectEmployeesPlanningToSubmitForThisCustomerForThisWeek, Schedule, emptyAvailableAndNonAvailableEmployees, setAvailableAndNonAvailableEmployees } from '../../Reducers/EmployeePlanning';
import { selectCoachesPlanningToSubmitForThisCustomerForThisWeek, emptyAvailableAndNonAvailableCoaches, setAvailableAndNonAvailableCoaches, checkIfCoachForThisWeek } from '../../Reducers/CoachPlanning';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useTranslation } from "react-i18next";

function CreateTimetable() {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const toSendEmp = useSelector(selectEmployeesPlanningToSubmitForThisCustomerForThisWeek(params.customerId || ""))
    const toSendCoach = useSelector(selectCoachesPlanningToSubmitForThisCustomerForThisWeek(params.customerId || ""))
    const enoughCoaches = useSelector(checkIfCoachForThisWeek(params.customerId || "", params.firstDayOfWeek || ""))
    const [notEnoughCoaches, setNotEnoughCoaches] = React.useState(false);

    const getDatesOfWeekUntilFriday = (dateStr: string) => {
        const parsedDate = dayjs(dateStr, 'DD-MM-YYYY');
        const startOfWeek = parsedDate.startOf('week').add(1, 'day');
        const dates: string[] = [];
        for (let i = 0; i < 5; i++) {
            const date = startOfWeek.add(i, 'day').format('DD-MM-YYYY');
            dates.push(date);
        }
        return dates;
    };
    const weekDates = getDatesOfWeekUntilFriday(params.firstDayOfWeek || "");

    const { isLoading: timetableEmployeesLoading, data: employeeSchedule } = useQuery<Schedule>(
        ['employeeTimetable', params.firstDayOfWeek || ""],
        () => getAssignableAndNonAssignableEmployeesForThisWeek(params.firstDayOfWeek || ""),
        {
            retry: false,
            onSuccess: (data) => {
                dispatch(emptyAvailableAndNonAvailableEmployees())
                dispatch(setAvailableAndNonAvailableEmployees(data));
            },
            onError: (error: any) => {
                error.response.status == 403 ? navigate("/login") : console.log(error.response.data)
            }
        },
    );
    const { isLoading: timetableCustomersLoading, data: coachSchedule } = useQuery<Schedule>(
        ['coachTimeTable', params.firstDayOfWeek || ""],
        () => getAssignableAndNonAssignableCoachesForThisWeek(params.firstDayOfWeek || ""),
        {
            retry: 1,
            onSuccess: (data) => {
                dispatch(emptyAvailableAndNonAvailableCoaches())
                dispatch(setAvailableAndNonAvailableCoaches(data));
            },
            onError: (error: any) => {
                error.response.status == 403 ? navigate("/login") : console.log(error.response.data)
            }
        },
    );

    if (timetableEmployeesLoading || timetableCustomersLoading) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '80vh'
                }}
            >
                <CircularProgress />
            </div>
        );
    }

    return (
        <div >
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item xs={true}>
                    <Typography variant="h5" component="h2" sx={{ textAlign: "center" }}>
                        {location.state.customerName}
                    </Typography>
                    <Typography sx={{ textAlign: "center", marginBottom: notEnoughCoaches ? "0px" : "20px" }}>
                        {params.firstDayOfWeek}
                    </Typography>
                    {notEnoughCoaches &&
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                            <ErrorOutlineIcon style={{ fill: '#FF2D00' }} />
                            <p style={{ marginLeft: '2px' }}>{t('timetable.exceptions.not_enough_coaches')}</p>
                        </div>
                    }
                </Grid>
                <Grid item>
                    <Button variant="contained"
                        onClick={() => {
                            if (enoughCoaches) {
                                setNotEnoughCoaches(false);
                                createEmployeePlanning(toSendEmp, params.customerId)
                                    .then(() => {
                                        createCoachPlanning(toSendCoach, params.customerId);
                                    })
                                    .then(() => {
                                        queryClient.invalidateQueries(["timetableForWeek"]);
                                        navigate("/timetable/create");
                                    })
                                    .catch((error) => {
                                        // Handle any errors here
                                    });
                            } else {
                                setNotEnoughCoaches(true);
                            }
                        }}
                    >
                        {t('steps.save')}
                    </Button>
                </Grid>
            </Grid>

            <Grid container justifyContent="center" spacing={5}>
                {weekDates.map((date) => (
                    <Grid key={date} item xs={9} sm={4} md={3} lg={2}>
                        <TimetableCard
                            date={date.toString()}
                            customerId={params.customerId}
                            customerName={location.state.customerName}
                            numberOfEmployeesNeeded={location.state.numberOfEmployeesNeeded}
                        />
                    </Grid>
                ))}
            </Grid>
        </div >
    )
}

export default CreateTimetable