
export class CreateJobDto {
    jobDescription: string;
    numberOfEmployees: number;
    constructor(
        jobDescription: string,
        numberOfEmployees: number,
    ) {
        this.jobDescription = jobDescription;
        this.numberOfEmployees = numberOfEmployees;
    }

    toJson() {
        return {
            jobDescription: this.jobDescription,
            numberOfEmployees: this.numberOfEmployees,
        }
    }
}