import * as React from "react";
import { AppBar, Box, Toolbar, Container, Button, Menu, IconButton, MenuItem, Typography } from '@mui/material';
import Logo from "../assets/ergasia.png";
import { Link, NavLink, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { Me } from "../Reducers/Models/Me";
import axios from "axios";
import { logoutState } from "../Reducers/Auth";
import { useDispatch } from "react-redux";
import PersonIcon from '@mui/icons-material/Person';
import Person from "@mui/icons-material/Person";

function Navbar({ user }: { user: Me | null }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [anchorElCreate, setAnchorElCreate] = React.useState<null | HTMLElement>(null);
  const [anchorElProfile, setAnchorElProfile] = React.useState<null | HTMLElement>(null);
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenProfileMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElProfile(event.currentTarget);
  };

  const handleCloseProfileMenu = () => {
    setAnchorElProfile(null);
  };

  const handleOpenCreateMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElCreate(event.currentTarget);
  };

  const handleCloseCreateMenu = () => {
    setAnchorElCreate(null);
  };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleEditProfile = () => {
    navigate(`/coaches/${user?.userId?.toString()}/edit`);
  }

  const logout = () => {
    axios.get("/auth/logout").then((response) => {
      dispatch(logoutState());
    }).then(() => { navigate('/login') })
  };

  const linkStyleSelected = {
    color: 'black',
    fontWeight: 'bold',
    textDecoration: 'none'
  };

  const linkStyleDefault = {
    color: 'black',
    textDecoration: 'none'
  };

  return (

    <AppBar sx={{ backgroundColor: '#facd69', height: '12%' }}>
      <Container maxWidth={false}>
        <Toolbar disableGutters>

          <Box
            component="img"
            sx={{
              width: "100px",
              bottom: '-25px',
              position: 'relative',
              display: { xs: 'none', md: 'flex' }
            }}
            src={Logo}
          />

          <Box
            component="img"
            sx={{
              width: "10vh",
              bottom: '-25px',
              position: 'relative',
              display: { xs: 'flex', md: 'none' }
            }}
            src={Logo}
          />

          <Box sx={{ marginLeft: 'auto', marginRight: "5vw", display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {user &&
                <>
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Link to="/timetable" style={location.pathname === '/timetable' || location.pathname === '/' || location.pathname === '' ? linkStyleSelected : linkStyleDefault}>
                      {t('navbar.weekly_overview')}
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Link to="/employees" style={location.pathname === '/employees' ? linkStyleSelected : linkStyleDefault}>
                      {t('navbar.employees')}
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Link to="/customers" style={location.pathname === '/customers' ? linkStyleSelected : linkStyleDefault}>
                      {t('navbar.customers')}
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Link to="/coaches" style={location.pathname === '/coaches' ? linkStyleSelected : linkStyleDefault}>
                      {t('navbar.coaches')}
                    </Link>
                  </MenuItem>


                  {user?.role == "ROLE_ADMIN" &&
                    <MenuItem onClick={handleCloseNavMenu}>
                      <Button
                        sx={location.pathname === '/timetable/create' ? linkStyleSelected : linkStyleDefault}
                        onClick={() => navigate(`/timetable/create`, { state: { currentWeek: true } })}>
                        {t('navbar.weekly_planning')}
                      </Button>
                    </MenuItem>
                  }
                  {user?.role == "ROLE_ADMIN" &&
                    <MenuItem onClick={handleCloseNavMenu}>
                      <Button
                        sx={location.pathname === '/reports' ? linkStyleSelected : linkStyleDefault}
                        onClick={() => navigate(`/reports`)}>
                        {t('navbar.reports')}
                      </Button>
                    </MenuItem>
                  }
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Link to="/" style={location.pathname === '/dayreports/create' ? linkStyleSelected : linkStyleDefault}>
                      {t('navbar.dayreports')}
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography style={location.pathname === '/login' ? linkStyleSelected : linkStyleDefault} onClick={logout}>
                      Logout
                    </Typography>
                  </MenuItem>
                </>
              }
              {
                !user &&
                <MenuItem onClick={handleCloseNavMenu}>
                  <Link to="/login" style={location.pathname === '/login' ? linkStyleSelected : linkStyleDefault}>
                    Login
                  </Link>
                </MenuItem>
              }
            </Menu>
          </Box>

          <Box sx={{ marginLeft: 'auto', marginRight: "5vw", display: { xs: 'none', md: 'flex' } }}>
            {user &&
              <>
                <Button >
                  <Link to="/absences" style={location.pathname === '/absences' ? linkStyleSelected : linkStyleDefault}>
                    Afwezigheden
                  </Link>
                </Button>
                <Button >
                  <Link to="/timetable" style={location.pathname === '/timetable' || location.pathname === '/' || location.pathname === '' ? linkStyleSelected : linkStyleDefault}>
                    {t('navbar.weekly_overview')}
                  </Link>
                </Button>
                <Button sx={{ my: 2 }} style={location.pathname === '/employees' || location.pathname === '/customers' || location.pathname === '/coaches' ? linkStyleSelected : linkStyleDefault} onClick={handleOpenUserMenu}>
                  {t('navbar.profile_management')}

                </Button>
                <Menu
                  sx={{ mt: '45px' }}
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <Button >
                    <Link to="/employees" style={location.pathname === '/employees' ? linkStyleSelected : linkStyleDefault}>
                      {t('navbar.employees')}
                    </Link>
                  </Button>
                  <Button >
                    <Link to="/customers" style={location.pathname === '/customers' ? linkStyleSelected : linkStyleDefault}>
                      {t('navbar.customers')}
                    </Link>
                  </Button>
                  <Button >
                    <Link to="/coaches" style={location.pathname === '/coaches' ? linkStyleSelected : linkStyleDefault}>
                      {t('navbar.coaches')}
                    </Link>
                  </Button>
                </Menu>


                <Button sx={{ my: 2 }} style={location.pathname === '/timetable/create' || location.pathname === '/dayreports/create' ? linkStyleSelected : linkStyleDefault} onClick={handleOpenCreateMenu}>
                  {t('navbar.create')}
                </Button>
                <Menu
                  sx={{ mt: '45px' }}
                  anchorEl={anchorElCreate}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElCreate)}
                  onClose={handleCloseCreateMenu}
                >
                  {user?.role == "ROLE_ADMIN" &&
                    <Button
                      sx={location.pathname === '/timetable/create' ? linkStyleSelected : linkStyleDefault}
                      onClick={() => navigate(`/timetable/create`, { state: { currentWeek: true } })}>
                      {t('navbar.weekly_planning')}
                    </Button>
                  }
                  <Button >
                    <Link to="/" style={location.pathname === '/dayreports/create' ? linkStyleSelected : linkStyleDefault}>
                      {t('navbar.dayreports')}
                    </Link>
                  </Button>
                </Menu>
                {user?.role == "ROLE_ADMIN" && <Button >
                  <Link to="/reports" style={location.pathname === '/reports' ? linkStyleSelected : linkStyleDefault}>
                    {t('navbar.reports')}
                  </Link>
                </Button>}
                <Button onClick={handleOpenProfileMenu}>
                  <PersonIcon sx={{ color: "black" }} />
                </Button>
                <Menu
                  sx={{ mt: '45px' }}
                  anchorEl={anchorElProfile}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElProfile)}
                  onClose={handleCloseProfileMenu}
                >
                  <MenuItem onClick={handleCloseProfileMenu}>{/*  /coaches/${actor.personId?.toString()}/edit  */}
                    <Typography onClick={handleEditProfile} style={location.pathname === '/coaches/:coachId/edit' ? linkStyleSelected : linkStyleDefault} >
                      Wijzig gegevens
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={handleCloseProfileMenu}>
                    <Link to="/change_password" style={location.pathname === '/change_password' ? linkStyleSelected : linkStyleDefault}>
                      Wijzig wachtwoord
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleCloseProfileMenu}>
                    <Typography onClick={logout} style={linkStyleDefault}>
                      Logout
                    </Typography>
                  </MenuItem>
                </Menu>
              </>
            }
            {
              !user &&
              <Button >
                <Link to="/login" style={location.pathname === '/login' ? linkStyleSelected : linkStyleDefault}>
                  Login
                </Link>
              </Button>
            }
          </Box>
        </Toolbar>
      </Container>
    </AppBar>

  );
}

export default Navbar;

