import React from 'react';
import dayjs, {Dayjs} from "dayjs";
import {getTimetableForWeek} from '../../Services/DataService';
import {useQuery, useQueryClient} from '@tanstack/react-query'
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    CircularProgress,
    Grid,
    IconButton,
    Typography
} from '@mui/material';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import EditIcon from '@mui/icons-material/Edit';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import {Me} from '../../Reducers/Models/Me';

function ViewTimetable({user}: { user: Me | null }) {
    const {t} = useTranslation();
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const [selectedDate, setSelectedDate] = React.useState<Dayjs>(dayjs().startOf('week').add(1, 'day'));

    const {isLoading, data} = useQuery(
        ["timetableForWeek"],
        (): Promise<any> => getTimetableForWeek(selectedDate.format('DD-MM-YYYY')),
        {
            retry: 0,
            staleTime: 300000,
            onError: (error: any) => {
                error.response.status == 403 ? navigate('/login') : console.log(error.response.data);
            }
        }
    );

    if (isLoading) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '80vh'
                }}
            >
                <CircularProgress/>
            </div>
        );
    }

    if (!data || data.length === 0 || Object.keys(data).length === 0) {
        return (
            <Grid container>
                <Grid item xs={9}>
                    <p>{t('timetable.exceptions.no_planning')}</p>
                </Grid>
            </Grid>
        );
    }

    const containerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
    };

    const handleNext = () => () => {
        queryClient.removeQueries(["timetableForWeek"]);
        let nextDate = selectedDate.add(7, "day");
        setSelectedDate(nextDate);

    };

    const handlePrevious = () => () => {
        queryClient.removeQueries(["timetableForWeek"]);
        let nextDate = selectedDate.subtract(7, "day");
        setSelectedDate(nextDate);
    };

    return (
        <>
            <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                <Grid container xs={12} sm={true} sx={{mb: "2vh", width: "65%"}}>
                    <Grid container justifyContent="center" alignItems="center" spacing={1}>
                        <Grid item>
                            <IconButton size="small" color="primary" onClick={handlePrevious()}>
                                <KeyboardDoubleArrowLeftIcon/>
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <Typography>
                                {`${selectedDate.format("DD/MM/YYYY")} - ${selectedDate.add(4, "day").format("DD/MM/YYYY")}`}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton size="small" color="primary" onClick={handleNext()}>
                                <KeyboardDoubleArrowRightIcon/>
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </div>

            {Object.keys(data).map((customerName) => {
                const customerData = data[customerName];
                const customerId = customerData.customerId;
                const customerTimetable = customerData.timetable;
                const sortedDates = Object.keys(customerTimetable).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

                return (
                    <div style={containerStyle} key={customerName}>
                        <TableContainer component={Paper} sx={{mb: "2vh", width: "65%"}}>
                            <Grid container display="flex" justifyContent="flex-end" sx={{bgcolor: "#facd69"}}>
                                <Grid item>
                                    {user?.role == "ROLE_ADMIN" &&
                                        <IconButton
                                            onClick={() => navigate(`/timetable/${customerId}/${selectedDate.format('DD-MM-YYYY')}/edit`, {state: {customerName: customerName}})}>
                                            <EditIcon/>
                                        </IconButton>
                                    }
                                </Grid>
                            </Grid>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center"
                                                   sx={{bgcolor: "#facd69", width: "15vw", fontWeight: 'bold'}}>
                                            {customerName}
                                        </TableCell>
                                        {sortedDates.map((date) => (
                                            <TableCell align="center" key={date}
                                                       sx={{bgcolor: "#fff9ed", width: "8vw"}}>
                                                {dayjs(date).format('DD/MM/YYYY')}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell sx={{
                                            width: "15vw",
                                            fontWeight: 'bold'
                                        }}>{t('timetable.view.coaches')}</TableCell>
                                        {sortedDates.map((date) => (
                                            <TableCell align="center" key={date} sx={{
                                                width: "8vw",
                                                borderLeft: "1px solid #e0e0e0",
                                                borderRight: "1px solid #e0e0e0"
                                            }}>
                                                {
                                                    customerTimetable[date].coaches
                                                        ?.sort((a: any, b: any) => a.firstName.localeCompare(b.firstName))
                                                        .map((coach: any, index: number, array: any[]) => (
                                                            <React.Fragment key={index}>
                                                                {coach.firstName}
                                                                {index < array.length - 1 && <br/>}
                                                            </React.Fragment>
                                                        ))
                                                }
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{
                                            width: "15vw",
                                            fontWeight: 'bold'
                                        }}>{t('timetable.view.employees')}</TableCell>
                                        {sortedDates.map((date) => (
                                            <TableCell align="center" key={date} sx={{
                                                width: "8vw",
                                                borderLeft: "1px solid #e0e0e0",
                                                borderRight: "1px solid #e0e0e0"
                                            }}>
                                                {
                                                    customerTimetable[date].employees
                                                        ?.sort((a: any, b: any) => a.firstName.localeCompare(b.firstName))
                                                        .map((employee: any, index: number, array: any[]) => (
                                                            <React.Fragment key={index}>
                                                                {employee.firstName}
                                                                {index < array.length - 1 && <br/>}
                                                            </React.Fragment>
                                                        ))
                                                }
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                );
            })}
        </>
    );

}

export default ViewTimetable

