import React from "react";
import { TextField, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CoachEmployee } from "../../Models/CoachEmployee";
import { Customer } from "../../Models/Customer";

const AddressInformation = ({ register, coach, customer }: { register: any, coach?: CoachEmployee, customer?: Customer }) => {
    const { t } = useTranslation();

    return (
        <Box sx={{ marginTop: "2vh", display: "flex", flexDirection: "column" }}>
            <Box sx={{ width: { xs: "80vw", md: "50vw" }, alignSelf: "center" }}>
                {getTextField(t('crud.fields.street'), coach ? coach.address.streetName : (customer ? customer.address.streetName : ""), { ...register("streetName") }, 1.5, "100%", "55%", "2%")}
                {getTextField(t('crud.fields.houseNumber'), coach ? coach.address.houseNumber : (customer ? customer.address.houseNumber : ""), { ...register("houseNumber") }, 1.5, "63%", "26%", "2%")}
                {getTextField(t('crud.fields.postboxNumber'), coach ? coach.address.postboxNumber : (customer ? customer.address.postboxNumber : ""), { ...register("postboxNumber") }, 1.5, "35%", "15%")}
                {getTextField(t('crud.fields.postalCode'), coach ? coach.address.postalCode : (customer ? customer.address.postalCode : ""), { ...register("postalCode") }, 1.5, "100%", "49%", "2%")}
                {getTextField(t('crud.fields.city'), coach ? coach.address.city : (customer ? customer.address.city : ""), { ...register("city") }, 1.5, "100%", "49%")}
                {getTextField(t('crud.fields.country'), coach ? coach.address.country : (customer ? customer.address.country : ""), { ...register("country") }, 1.5, "100%", "100%", undefined, undefined, "België")}
            </Box>
        </Box>
    );
};

export default AddressInformation;

function getTextField(label: string, defaultValue: string | number, register: any, mb: number = 1.5, widthXs: string = "100%", widthSm: string = "32%", mr?: string, height?: string, value?: string) {
    return (
        <TextField
            label={label}
            defaultValue={defaultValue}
            variant="outlined"
            value={value}
            sx={{ mb: mb, width: { xs: widthXs, sm: widthSm }, mr: mr ? mr : "0%", height: height }}
            {...register}
        />
    );
}
