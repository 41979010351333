import React from 'react';
import { Grid, FormControl, InputLabel, Select, MenuItem, Button, Typography, } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs'; // Import dayjs
import { useForm } from "react-hook-form";
import { useQueryClient, useQuery } from '@tanstack/react-query'
import { Absence } from '../Models/Absence'
import { deleteAbsence, getAbsencesForPeriod } from '../Services/DataService'
import { Card, CardActions, CardContent } from '@mui/material';
import { useMutation } from '@tanstack/react-query'
import { deleteCoach, deleteCustomer, deleteEmployee } from '../Services/DataService';
import { useNavigate } from "react-router-dom";
import { ErrorElement } from '../Models/dto/ErrorElement'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useState } from "react";
import { CircularProgress } from "@mui/material";

const LookupAbsences = () => {
    const navigate = useNavigate();
    const [postError, setError] = useState<ErrorElement>()
    const querycl = useQueryClient();
    const [selectedFromDateFilter, setSelectedFromDateFilter] = React.useState<any>(undefined);
    const [selectedToDateFilter, setSelectedToDateFilter] = React.useState<any>(undefined);

    const handleFromDateChange = (newValue:any) => {
        setSelectedFromDateFilter(newValue ? newValue.format('DD-MM-YYYY') : null);
    };

    const handleToDateChange = (newValue:any) => {
        setSelectedToDateFilter(newValue ? newValue.format('DD-MM-YYYY') : null);
    };

    const { isLoading: absencesLoading, data: absences } = useQuery<Absence[]>(
        ['absences', selectedFromDateFilter, selectedToDateFilter],
        () => getAbsencesForPeriod(selectedFromDateFilter?.toString(), selectedToDateFilter.toString()), {
        staleTime: 300000,
        retry: 0,
        enabled: (selectedFromDateFilter && selectedToDateFilter) ? true : false
    }
    );

    const { mutate: deleteAbsenceMutation, isLoading: deleteAbsenceLoading } = useMutation(deleteAbsence, {
        onSuccess: () => {
            querycl.invalidateQueries(["absences", selectedFromDateFilter, selectedToDateFilter]);
        }
    });


    return (
        <>
            <Grid item container justifyContent="center" alignItems="center" spacing={2} mb={"2vh"}>
                <Grid item sm={4} xs={6} md={4} lg={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nl-be">
                        <DatePicker onChange={handleFromDateChange} label={"Vanaf"} />
                    </LocalizationProvider>
                </Grid>
                <Grid item sm={4} xs={6} md={4} lg={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nl-be">
                        <DatePicker onChange={handleToDateChange} label={"Tot"} />
                    </LocalizationProvider>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                {absences?.map((absence) => (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Card>
                            <CardContent>
                                <Typography variant="h5" component="div">
                                    {absence?.person}
                                </Typography>
                                <Typography variant="body2">
                                    {absence?.fromDate} - {absence?.untilDate}
                                </Typography>
                                <Typography variant="body2">
                                    {absence?.reason}
                                </Typography>
                            </CardContent>
                            <CardActions onClick={() => { deleteAbsenceMutation(absence.absenceId) }}>
                                <Button size="small">Verwijder</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
                {postError &&
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{ display: 'flex', textAlign: "left", alignItems: 'center', width: "25vw" }}>
                            <ErrorOutlineIcon style={{ fill: '#FF2D00' }} />
                            <p style={{ marginLeft: '2px' }}>{postError.errorCode}</p>
                        </div>
                    </div>
                }
            </Grid>
        </>
    );
}

export default LookupAbsences;
