import React from 'react';
import { Card, Divider, CardContent, ListItemButton, ListItemText, Collapse, List } from '@mui/material';
import { setEmployeeAsNonAvailable, setEmployeeAsAvailable, selectPlannedEmployeesForThisCustomer, selectNonAvailableEmployeesForCustomer, selectAvailableEmployeesForCustomer } from '../../Reducers/EmployeePlanning';
import { useSelector, TypedUseSelectorHook, useDispatch } from "react-redux";
import { RootState } from "../../Reducers/ReduxStore";
import { Person, PersonWithCustomer } from '../../Reducers/Models/Person';
import { selectAvailableCoachesForCustomer, selectNonAvailableCoachesForCustomer, selectPlannedCoachesForThisCustomer, setCoachAsAvailable, setCoachAsNonAvailable } from '../../Reducers/CoachPlanning';
const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
import { useQueryClient } from '@tanstack/react-query'
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

function TimetableCard({ date, customerId, customerName, numberOfEmployeesNeeded }:
    { date: string, customerId: string | undefined, customerName: string | undefined, numberOfEmployeesNeeded: number | undefined }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const queryClient = useQueryClient()
    const plannedEmployees = useSelector(selectPlannedEmployeesForThisCustomer(date, customerName || ""));
    const nonAvailableEmployees = useSelector(selectNonAvailableEmployeesForCustomer(date, customerName || ""));
    const availableEmployees = useSelector(selectAvailableEmployeesForCustomer(date));
    const plannedCoaches = useSelector(selectPlannedCoachesForThisCustomer(date, customerName || ""));
    const nonAvailableCoaches = useSelector(selectNonAvailableCoachesForCustomer(date, customerName || ""));
    const availableCoaches = useSelector(selectAvailableCoachesForCustomer(date, customerName || ""));
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <Card
            sx={{
                backgroundColor: "white",
                boxShadow: "5",
                borderRadius: 3
            }}>
            <ListItemText primary={date} secondary={`${plannedEmployees?.length || 0}/${numberOfEmployeesNeeded}`} sx={{ textAlign: "center" }} />
            <Divider />
            <CardContent sx={{ flex: '1 0 auto', bgcolor: "#f3f3f3", height: "20vh", overflow: "auto" }}>
                {plannedEmployees?.map((person: Person) => (
                    <ListItemButton key={person.personId} onClick={() => {
                        dispatch(setEmployeeAsAvailable(
                            {
                                customerId: customerId,
                                personId: person.personId,
                                customerName: customerName,
                                firstName: person.firstName,
                                lastName: person.lastName,
                                date: date,
                            }
                        ))
                        queryClient.invalidateQueries(["employee", person.personId.toString()])
                    }}>
                        <ListItemText primary={`${person.firstName} ${person.lastName}`} />
                    </ListItemButton>
                ))}
                {plannedCoaches?.map((person: Person) => (
                    <ListItemButton key={person.personId} onClick={() => {
                        dispatch(setCoachAsAvailable(
                            {
                                customerId: customerId,
                                personId: person.personId,
                                customerName: customerName,
                                firstName: person.firstName,
                                lastName: person.lastName,
                                date: date,
                            }
                        ))
                    }}>
                        <ListItemText primary={`${person.firstName} ${person.lastName}`} sx={{ color: '#fe8964' }} />
                    </ListItemButton>
                ))}
            </CardContent>
            <Divider />
            <CardContent sx={{ flex: '1 0 auto', height: "25vh", overflow: "auto" }}>
                <ListItemText primary={t('timetable.create.available')} sx={{ fontWeight: "bold" }} />
                {availableEmployees?.map((person: Person) => (
                    <ListItemButton key={person.personId} onClick={() => {
                        dispatch(setEmployeeAsNonAvailable({
                            customerId: customerId,
                            personId: person.personId,
                            customerName: customerName,
                            firstName: person.firstName,
                            lastName: person.lastName,
                            date: date,
                        }))
                        queryClient.invalidateQueries(["employee", person.personId.toString()])
                    }}>
                        <ListItemText primary={`${person.firstName} ${person.lastName}`} />
                    </ListItemButton>
                ))}
                {availableCoaches?.map((person: Person) => (
                    <ListItemButton key={person.personId} onClick={() => {
                        dispatch(setCoachAsNonAvailable({
                            customerId: customerId,
                            personId: person.personId,
                            customerName: customerName,
                            firstName: person.firstName,
                            lastName: person.lastName,
                            date: date,
                        }))
                    }}>
                        <ListItemText primary={`${person.firstName} ${person.lastName}`} sx={{ color: '#fe8964' }} />
                    </ListItemButton>
                ))}
            </CardContent>
            <Divider />
            <CardContent>
                <ListItemButton onClick={handleClick}>
                    <ListItemText primary={t('timetable.create.unavailable')} />
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open} >
                    <List disablePadding>
                        {nonAvailableEmployees?.map((person: PersonWithCustomer) => (
                            <ListItemButton key={person.personId} onClick={() => {
                                dispatch(setEmployeeAsAvailable(
                                    {
                                        customerId: customerId,
                                        personId: person.personId,
                                        customerName: customerName,
                                        firstName: person.firstName,
                                        lastName: person.lastName,
                                        date: date,
                                    }
                                ))
                                dispatch(setEmployeeAsNonAvailable({
                                    customerId: customerId,
                                    personId: person.personId,
                                    customerName: customerName,
                                    firstName: person.firstName,
                                    lastName: person.lastName,
                                    date: date,
                                }))
                                queryClient.invalidateQueries(["employee", person.personId.toString()])
                            }}>
                                <ListItemText primary={`${person.firstName} ${person.lastName} - ${person.customerName}`} />
                            </ListItemButton>
                        ))}
                        {nonAvailableCoaches?.map((person: PersonWithCustomer) => (
                            <ListItemButton key={person.personId} onClick={() => {
                                dispatch(setCoachAsAvailable(
                                    {
                                        customerId: customerId,
                                        personId: person.personId,
                                        customerName: customerName,
                                        firstName: person.firstName,
                                        lastName: person.lastName,
                                        date: date,
                                    }
                                ))
                                dispatch(setCoachAsNonAvailable({
                                    customerId: customerId,
                                    personId: person.personId,
                                    customerName: customerName,
                                    firstName: person.firstName,
                                    lastName: person.lastName,
                                    date: date,
                                }))
                            }}>
                                <ListItemText primary={`${person.firstName} ${person.lastName} - ${person.customerName}`} sx={{ color: "#fe8964" }} />
                            </ListItemButton>
                        ))}
                    </List>
                </Collapse>
            </CardContent>
        </Card>
    )
}

export default TimetableCard