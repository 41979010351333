import React, { useState } from "react";
import { Typography, TextField, Box, Button, FormControlLabel, Checkbox } from "@mui/material";
import { Customer } from "../../Models/Customer";
import { useTranslation } from "react-i18next";

const WorkHours = ({ register, customer }: { register: any, customer?: Customer }) => {
    const { t } = useTranslation();
    const existingPauzesCount = customer ? (customer.workSchedule.workBreaks != undefined ? customer?.workSchedule.workBreaks.length : 0) : 0;
    const [pauzesCount, setPauzesCount] = useState(() => customer ? existingPauzesCount : 0);

    const handleAddPauzesField = () => {
        setPauzesCount((prevCount) => prevCount + 1);
    };

    return (
        <Box sx={{ marginTop: "2vh", display: "flex", flexDirection: "column" }}>
            <Box sx={{ width: { xs: "80vw", md: "50vw" }, alignSelf: "center" }}>
                <Typography variant="h6" component="h2">Werkuren</Typography>
                {getTextField(t('crud.fields.start_hour'), customer ? customer?.workSchedule?.startTime : '', { ...register("startTime") }, 1.5, "100%", "48%", "2%")}
                {getTextField(t("crud.fields.end_hour"), customer ? customer?.workSchedule?.endTime : '', { ...register("endTime") }, 1.5, "100%", "48%")}
                <input
                    type="hidden"
                    defaultValue={customer?.workSchedule.workScheduleId || ""}
                    {...register(`workScheduleId`)}
                />
                <Typography variant="h6" component="h2">Pauzes</Typography>
                {Array.from({ length: pauzesCount }).map((_, index) => (
                    <div key={index}>
                        {getTextField(t('crud.fields.start_hour'), customer ? customer?.workSchedule.workBreaks[index]?.startTime : '', { ...register(`breaks[${index}].startTime`) }, 1.5, "100%", "48%", "2%")}
                        {getTextField(t("crud.fields.end_hour"), customer ? customer?.workSchedule.workBreaks[index]?.endTime : '', { ...register(`breaks[${index}].endTime`) }, 0, "100%", "48%")}
                        <FormControlLabel control={<Checkbox defaultChecked={customer ? customer.workSchedule.workBreaks[index].paid : false} />} label={t('crud.fields.paid')} sx={{ mb: 1.5 }} {...register(`breaks[${index}].paid`)} />
                        <input
                            type="hidden"
                            defaultValue={customer?.workSchedule.workBreaks[index]?.breakId || ""}
                            {...register(`breaks[${index}].breakId`)}
                        />
                    </div>
                ))}
                <Button onClick={handleAddPauzesField} variant="outlined">
                    {t("crud.create_break")}
                </Button>
            </Box>
        </Box>
    );
};

export default WorkHours;

function getTextField(label: string, defaultValue: string | number, register: any, mb: number = 1.5, widthXs: string = "100%", widthSm: string = "32%", mr?: string, height?: string) {
    return (
        <TextField
            label={label}
            defaultValue={defaultValue}
            variant="outlined"
            sx={{ mb: mb, width: { xs: widthXs, sm: widthSm }, mr: mr ? mr : "0%", height: height }}
            {...register}
        />
    );
}