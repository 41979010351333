import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from "i18next-browser-languagedetector"

i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        lng: 'nl',
        fallbackLng: "nl",
        resources: {
            nl: {
                translation: {
                    crud: {
                        fields: {
                            lastName: "Naam",
                            firstName: "Voornaam",
                            email: "Emailadres",
                            telephoneNumber: "Telefoonnummer",
                            street: "Straat",
                            houseNumber: "Nummer",
                            postboxNumber: "Bus",
                            postalCode: "Postcode",
                            city: "Gemeente",
                            country: "Land",
                            address: "Adres",
                            customerName: "Bedrijfsnaam",
                            contactPerson: "Contactpersoon",
                            start_hour: "Startuur",
                            end_hour: "Einduur",
                            paid: "Betaald?",
                            number_of_employees: "Aantal werknemers",
                            date: "Datum",
                            customer: "Klant",
                        },
                        exceptions: {
                            lastName: "Gelieve een achternaam in te vullen.",
                            firstName: "Gelieve een voornaam in te vullen.",
                            email: "Gelieve een geldig emailadres in te vullen.",
                            emailRequired: "Gelieve een emailadres in te vullen.",
                            telephoneNumber: "Gelieve een telefoonnummer in te vullen.",
                            streetName: "Gelieve de straatnaam na te kijken.",
                            houseNumber: "Gelieve het huisnummer na te kijken.",
                            postalCode: "Gelieve de postcode na te kijken.",
                            city: "Gelieve de gemeente na te kijken.",
                            hourlyWage: "Gelieve het uurloon na te kijken.",
                            wrong_time_format: "Foutief formaat. Tijdstip moet in HH:MM, 24-uurs formaat zijn.",
                            starttime: "Gelieve een starttijd in te vullen.",
                            endtime: "Gelieve een eindtijd in te vullen.",
                            type_text: "Gelieve een tekst in te vullen.",
                            type_number: "Gelieve een numerieke waarde in te vullen.",
                        },
                        actions: "Acties",
                        edit: "Bewerk",
                        delete: "Verwijder",
                        details: "Details",
                        create_break: "Voeg pauze toe",
                        watch: "Bekijk",
                        detail: {
                            personalInformation: "Persoonlijke informatie",
                            generalInformation: "Algemene informatie",
                            addressInformation: "Adresgegevens",
                            contactInformation: "Contactgegevens",
                            jobInformation: "Job informatie",
                            jobDescription: "Jobomschrijving",
                            numberOfEmployees: "Aantal werknemers",
                            workSchedule: "Werkrooster",
                            workTimes: "Werkuren",
                            breaks: "Pauzes",
                            job: "Job",
                            dayreports: "Dagrapporten",
                        },
                    },
                    coach: {
                        coach: "Coach",
                        coaches: "Coaches",
                        create: {
                            title: "Voeg nieuwe coach toe",
                        },
                        update: {
                            title: "Pas {{firstName}} {{lastName}} aan"
                        },
                        notFoundAll: "Er zijn geen coaches gevonden.",
                    },
                    employee: {
                        employees: "Doelgroepmedewerkers",
                        notFoundAll: "Er zijn geen doelgroepmedewerkers gevonden.",
                        create: {
                            title: "Voeg nieuwe doelgroepmedewerker toe",
                        },
                        update: {
                            title: "Pas {{firstName}} {{lastName}} aan"
                        },
                    },
                    customer: {
                        customers: "Klanten",
                        customers_lowercase: "klanten",
                        customer: "Klant",
                        notFoundAll: "Er zijn geen klanten gevonden.",
                        create: {
                            title: "Voeg nieuwe klant toe",
                        },
                        update: {
                            title: "Pas {{customerName}} aan"
                        },
                    },
                    steps: {
                        personal: "Persoonlijke informatie",
                        address: "Adresgegevens",
                        next: "Volgende",
                        save: "Sla op",
                        previous: "Vorige",
                        close: "Sluit",
                    },
                    exceptions: {
                        FOUND: "{{input}} bestaat al in het systeem.",
                        VALIDATION: "Ongeldige invoer op het veld '{{field}}'",
                        NOT_EXIST: "{{input}} bestaat niet in het systeem."
                    },
                    navbar: {
                        weekly_overview: "Weekoverzicht",
                        employees: "Doelgroepmedewerkers",
                        coaches: "Coaches",
                        customers: "Klanten",
                        weekly_planning: "Weekplanning",
                        reports: "Rapporten",
                        profile_management: "Profielbeheer",
                        create: "Aanmaken",
                        dayreports: "Dagrapporten",
                    },
                    dayreport_form: {
                        start_time: "Starttijd",
                        end_time: "Eindtijd",
                        transport_type: "Vervoerstype",
                        comments: "Opmerkingen",
                        not_produced_paid_hours: "Niet geproduceerde, wel betaalde uren",
                        not_produces_not_paid_hours: "Niet geproduceerde, niet betaalde uren",
                        explanation: "Uitleg"
                    },
                    timetable: {
                        view: {
                            employees: "Doelgroepmedewerker(s)",
                            coaches: "Coach(es)",
                        },
                        create: {
                            available: "Beschikbaar",
                            unavailable: "Reeds ingepland",
                        },
                        exceptions: {
                            not_enough_coaches: "Niet elke dag heeft een coach toegewezen gekregen!",
                            no_planning: "Er is geen planning gevonden."
                        }
                    },
                    reports: {
                        draft_invoices: "Voorbereiding facturatie",
                        actions: {
                            generate_invoices: "Genereer facturen",
                        }
                    }
                }
            }
        }
    });