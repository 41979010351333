import React, { useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs'; // Import dayjs
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { getAllCustomers, getInvoiceForClientForPeriod, getWagesForCustomers } from '../Services/DataService';
import { Customer } from '../Models/Customer';
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from "react-i18next";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { CustomerPrice } from '../Models/CustomerPrice';
import { CircularProgress, Table, TableBody, TableCell, TableHead, TableContainer, TableRow, Grid, Paper, Button, TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import NewCustomerPriceModal from '../Components/NewCustomerPriceModal';
import { useNavigate } from "react-router-dom";

function Reports() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [selectedFromDate, setSelectedFromDate] = React.useState(dayjs().format('DD-MM-YYYY'));
    const [selectedToDate, setSelectedToDate] = React.useState(dayjs().endOf('month').format('DD-MM-YYYY'));
    const { isLoading: customersLoading, data: customers } = useQuery<Customer[]>(['allCustomers'], getAllCustomers, { staleTime: 300000 });
    const [selectedCustomer, setSelectedCustomer] = React.useState<Customer | undefined>(undefined);
    const [value, setValue] = React.useState(0);

    const [openModal, setOpenModal] = React.useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    const handleFromDateChange = (newValue:any) => {
        setSelectedFromDate(newValue ? newValue.format('DD-MM-YYYY') : null);
    };

    const handleToDateChange = (newValue:any) => {
        setSelectedToDate(newValue ? newValue.format('DD-MM-YYYY') : null);
    };

    const handleChange = (event: SelectChangeEvent) => {
        const customerId = event.target.value;
        const customer = customers?.find(c => c.customerId === customerId);
        setSelectedCustomer(customer);
    };

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const { isLoading: customerWagesLoading, data: customerWages } = useQuery<Map<string, CustomerPrice[]>>(
        ['customerWages'],
        () => getWagesForCustomers(), {
        staleTime: 300000,
        onError: (error: any) => {
            error.response.status == 403 ? navigate("/login") : console.log(error.response.data);
        }
    }
    );

    if (customersLoading || customerWagesLoading) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '80vh'
                }}
            >
                <CircularProgress />
            </div>
        );
    }

    if ((!customers || customers.length === 0) && value == 0) {
        <p>
            Er liep iets min bij het ophalen van de klanten. Probeer later opnieuw.
        </p>
    }
    if (!customerWages || customerWages.size === 0 && value == 1) {
        <Grid container>
            <Grid item xs={12}>
                <Button onClick={handleOpenModal} variant="contained" fullWidth>Voeg nieuw tarief toe</Button>
            </Grid>
            <NewCustomerPriceModal open={openModal} handleClose={handleCloseModal} />
        </Grid>
    }

    return (
        <>
            <Grid container sx={{ mt: "5vh" }}>
                <Grid item xs={12} sx={{ backgroundColor: "#EE7223", mb: "1vh" }} >
                    <Tabs value={value} onChange={handleChangeTab} TabIndicatorProps={{ style: { backgroundColor: "transparent" } }}
                        sx={{
                            '& .MuiTabs-flexContainer': {
                                flexWrap: 'wrap',
                            },
                        }}
                    >
                        <Tab label={"Downloads"} style={{
                            backgroundColor: value == 0 ? "#C6332F" : "",
                            color: "white",
                        }}
                        />
                        <Tab label={"Tarieven"} style={{
                            backgroundColor: value == 1 ? "#C6332F" : "",
                            color: "white",
                        }}
                        />
                    </Tabs>
                </Grid>

                {value == 0 &&
                    <Grid container item>
                        <Grid item xs={12}>
                            <p>{t("reports.draft_invoices")}</p>
                        </Grid>
                        <Grid item container spacing={2} alignItems="center" width={"70vw"}>
                            <Grid item xs={12} md={4} sm={4} lg={4}>
                                <FormControl fullWidth>
                                    <InputLabel>{t("customer.customer")}</InputLabel>
                                    <Select
                                        value={selectedCustomer?.customerId || ""}
                                        label={t("customer.customer")}
                                        onChange={handleChange}
                                    >
                                        {customers?.map((customer) => (
                                            <MenuItem key={customer.customerId} value={customer.customerId}>
                                                {customer.customerName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item sm={4} xs={6} md={4} lg={2}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nl-be">
                                    <DatePicker onChange={handleFromDateChange} label={t('crud.fields.start_hour')} />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item sm={4} xs={6} md={4} lg={2}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nl-be">
                                    <DatePicker onChange={handleToDateChange} label={t('crud.fields.end_hour')} />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={4}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    onClick={() => getInvoiceForClientForPeriod(selectedCustomer, selectedFromDate, selectedToDate)}
                                >
                                    {t('reports.actions.generate_invoices')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                }
                {value == 1 &&
                    <Grid container>
                        <Grid item xs={12}>
                            <Button onClick={handleOpenModal} variant="contained" fullWidth>Voeg nieuw tarief toe</Button>
                        </Grid>
                        {Array.from(customerWages as Map<string, CustomerPrice[]>).map(([key, values]) => (
                            <Grid item xs={12} key={key} style={{ maxWidth: "50vw" }}>
                                <h2>{key.toUpperCase()}</h2>
                                <TableCustomerPrices originalRows={values} />
                            </Grid>
                        ))}
                        <NewCustomerPriceModal open={openModal} handleClose={handleCloseModal} />
                    </Grid>
                }

            </Grid>
        </>
    );

}

export default Reports


function TableCustomerPrices({ originalRows }: { originalRows: CustomerPrice[] }) {
    const [rows, setRows] = useState<CustomerPrice[]>(originalRows);

    const requestSearch = (searchedVal: string) => {
        const filteredRows = originalRows.filter((row) => {
            return (
                row.price === parseInt(searchedVal, 10) ||
                (row.employeeId || "").toString().toUpperCase().includes(searchedVal.toUpperCase())
            );
        });
        setRows(filteredRows);
    };

    return (
        <>

            {(originalRows.length === 1 && originalRows.at(0)?.type == "FIXED")
                ?
                <Paper>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" sx={{ fontWeight: "bold", width: "50%" }}>Type prijs</TableCell>
                                    <TableCell align="center" sx={{ fontWeight: "bold", width: "50%" }}>Uurprijs</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow key={row.customerPriceId}>
                                        <TableCell align="center">Vaste prijs</TableCell>
                                        <TableCell align="center">
                                            {`€ ${row.price.toFixed(2)}`}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
                :
                <Paper>
                    <TextField
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        onChange={(e) => {
                            requestSearch(e.target.value);
                        }}
                    />
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" sx={{ fontWeight: "bold", width: "50%" }}>Doelgroepmedewerker</TableCell>
                                    <TableCell align="center" sx={{ fontWeight: "bold", width: "50%" }}>Uurprijs</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow key={row.customerPriceId}>
                                        <TableCell align="center">{row?.employeeId}</TableCell>
                                        <TableCell align="center">
                                            {`€ ${row.price.toFixed(2)}`}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            }
        </>
    );

}
