import { useParams, useNavigate } from 'react-router-dom';
import React from 'react';
import { useQuery } from '@tanstack/react-query'
import { CircularProgress, Typography, Grid, Tabs, Tab, TextField, Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Link, Stack } from '@mui/material/';
import { getCoach, getCustomer, getEmployee, createDayReport } from '../Services/DataService'
import { useTranslation } from "react-i18next";
import { CoachEmployee } from '../Models/CoachEmployee';
import { Customer } from '../Models/Customer';
import { EmployeeDetail } from '../Models/EmployeeDetail';
import JobAssignmentForm from '../Components/JobAssignmentForm';
import { JobAssignment } from '../Models/JobAssignment';

function DetailPage({ coach, employee, customer }: { coach: boolean, employee: boolean, customer: boolean }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const [value, setValue] = React.useState(0);
    const [selectedJobAssignment, setSelectedJobAssignment] = React.useState<JobAssignment | null>(null);

    const { isLoading, data } = useQuery(
        [coach ? 'coach' : (employee ? 'employee' : 'customer'), coach ? params.coachId : (employee ? params.employeeId : params.customerId)],
        (): Promise<CoachEmployee | Customer | EmployeeDetail> => coach ? getCoach(params.coachId || "") : (employee ? getEmployee(params.employeeId || "") : getCustomer(params.customerId || "")),
        {
            onError: (error: any) => {
                error.response.status == 403 ? navigate("/login") : (coach ? navigate("/coaches") : (employee ? navigate("/employees") : navigate("/customers")))
            },
            retry: 0,
            staleTime: 300000
        }
    );

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };


    if (isLoading) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '80vh'
                }}
            >
                <CircularProgress />
            </div>
        );
    }

    return (
        <Grid container sx={{ mt: "5vh" }}>
            <Grid item xs={12} sx={{ mb: "3vh" }}>
                <Card sx={{ width: "fit-content", backgroundColor: "#DDDDDD", marginRight: "auto" }}>
                    <CardContent>
                        <Typography variant='h4' textAlign={"center"}>
                            {!customer ? `${(data as CoachEmployee)?.firstName} ${(data as CoachEmployee)?.lastName}` : (data as Customer)?.customerName}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12} sx={{ backgroundColor: "#EE7223", mb: "1vh" }} >
                <Tabs value={value} onChange={handleChange} TabIndicatorProps={{ style: { backgroundColor: "transparent" } }}
                    sx={{
                        '& .MuiTabs-flexContainer': {
                            flexWrap: 'wrap',
                        },
                    }}
                >
                    <Tab label={!customer ? t('crud.detail.personalInformation') : t('crud.detail.generalInformation')} style={{
                        backgroundColor: value == 0 ? "#C6332F" : "",
                        color: "white",
                    }}
                    />
                    {customer &&
                        <Tab label={t('crud.detail.jobInformation')} style={{
                            backgroundColor: value == 1 ? "#C6332F" : "",
                            color: "white",
                        }}
                        />}
                    {employee &&
                        <Tab label={t('crud.detail.dayreports')} style={{
                            backgroundColor: value == 1 ? "#C6332F" : "",
                            color: "white",
                        }}
                        />}
                </Tabs>
            </Grid>

            <Grid item xs={12}>
                {value == 0 &&
                    <Grid container spacing={2} >
                        <Grid item xs={12} md={6}>
                            <Card sx={{ minHeight: "40vh", backgroundColor: "#ECEBEA", boxShadow: 3 }}>
                                <CardContent>
                                    <Typography sx={{ textAlign: "center", fontWeight: "bold", mt: 1.5, marginBottom: "3vh", fontFamily: "Segoe UI", fontSize: "20px" }}>
                                        {t('crud.detail.contactInformation').toUpperCase()}
                                    </Typography>
                                    {customer &&
                                        <Stack
                                            direction={{ xs: 'column', md: 'row' }}
                                        >
                                            <Typography sx={{ fontWeight: "bold", width: { xs: '100%', md: '40%', lg: "25%" }, mt: { xs: 0, md: '2vh', lg: "2vh" } }} >
                                                {t('crud.fields.contactPerson')}
                                            </Typography>
                                            <TextField
                                                defaultValue={`${(data as Customer)?.contactPerson.firstName} ${(data as Customer)?.contactPerson.lastName}`}
                                                variant="outlined"
                                                disabled
                                                sx={{
                                                    mb: 1.5, width: { xs: '100%', md: '48%' },
                                                    "& .MuiInputBase-input.Mui-disabled": {
                                                        WebkitTextFillColor: "#000000",
                                                    }
                                                }}
                                            />
                                        </Stack>
                                    }
                                    <Stack
                                        direction={{ xs: 'column', md: 'row' }}
                                    >
                                        <Typography sx={{ fontWeight: "bold", width: { xs: '100%', md: '40%', lg: "25%" }, mt: { xs: 0, md: '2vh', lg: "2vh" } }}>
                                            {t('crud.fields.telephoneNumber')}
                                        </Typography>
                                        <TextField
                                            defaultValue={!customer ? (data as CoachEmployee)?.contactInformation.telephoneNumber : (data as Customer)?.contactPerson.contactInformation.telephoneNumber}
                                            variant="outlined"
                                            disabled
                                            sx={{
                                                mb: 1.5, width: { xs: '100%', md: '48%' },
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "#000000",
                                                }
                                            }}
                                        />
                                    </Stack>
                                    <Stack
                                        direction={{ xs: 'column', md: 'row' }}
                                    >
                                        <Typography sx={{ fontWeight: "bold", width: { xs: '100%', md: '40%', lg: "25%" }, mt: { xs: 0, md: '2vh', lg: "2vh" } }}>
                                            {t('crud.fields.email')}
                                        </Typography>
                                        <TextField
                                            defaultValue={!customer ? (data as CoachEmployee)?.contactInformation.email : (data as Customer)?.contactPerson.contactInformation.email}
                                            variant="outlined"
                                            disabled
                                            sx={{
                                                mb: 1.5, width: { xs: '100%', md: '48%' },
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "#000000",
                                                }
                                            }}
                                        />
                                    </Stack>


                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Card sx={{ minHeight: "40vh", backgroundColor: "#ECEBEA", boxShadow: 3 }}>
                                <CardContent>
                                    <Typography sx={{ textAlign: "center", fontWeight: "bold", mt: 1.5, marginBottom: "3vh", fontFamily: "Segoe UI", fontSize: "20px" }}>
                                        {t('crud.detail.addressInformation').toUpperCase()}
                                    </Typography>
                                    <Stack
                                        direction={{ xs: 'column', md: 'row' }}
                                    >
                                        <Typography sx={{ fontWeight: "bold", width: { xs: '100%', md: '40%', lg: "25%" }, mt: { xs: 0, md: '2vh', lg: "2vh" } }}>
                                            {t('crud.fields.address')}
                                        </Typography>
                                        <TextField
                                            defaultValue={`${data?.address.streetName} ${data?.address.houseNumber} ${data?.address.postboxNumber ? `, Bus ${data.address.postboxNumber}` : ""}`}
                                            variant="outlined"
                                            disabled
                                            sx={{
                                                mb: 1.5, width: { xs: '100%', md: '48%' },
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "#000000",
                                                }
                                            }}
                                        />
                                    </Stack>
                                    <div>
                                        <TextField
                                            defaultValue={`${data?.address.postalCode} ${data?.address.city}`}
                                            variant="outlined"
                                            disabled
                                            sx={{
                                                width: { xs: '100%', md: '48%' }, ml: { xs: '0%', md: '40%', lg: "25%" },
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "#000000",
                                                }
                                            }}
                                        />
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                }
                {(value == 1 && customer) &&
                    <Grid container spacing={2} >
                        <Grid item xs={12} md={6}>
                            <Card sx={{ backgroundColor: "#ECEBEA", boxShadow: 3, minHeight: "30vh" }}>
                                <CardContent>
                                    <Typography sx={{ textAlign: "center", fontWeight: "bold", mt: 1.5, marginBottom: "3vh", fontFamily: "Segoe UI", fontSize: "20px" }}>
                                        {t('crud.detail.workSchedule').toUpperCase()}
                                    </Typography>

                                    <Stack
                                        direction={{ xs: 'column', md: 'row' }}
                                    >
                                        <Typography sx={{ fontWeight: "bold", width: { xs: '100%', md: '40%', lg: "25%" }, mt: { xs: 0, md: '2vh', lg: "2vh" } }}>
                                            {t('crud.detail.workTimes')}
                                        </Typography>
                                        <TextField
                                            defaultValue={`${(data as Customer).workSchedule.startTime} - ${(data as Customer).workSchedule.endTime}`}
                                            variant="outlined"
                                            disabled
                                            sx={{
                                                mb: 1.5, width: { xs: '100%', md: '48%' },
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "#000000",
                                                }
                                            }}
                                        />
                                    </Stack>

                                    <Stack
                                        direction={{ xs: 'column', md: 'row' }}
                                    >
                                        <Typography sx={{ fontWeight: "bold", width: { xs: '100%', md: '40%', lg: "25%" }, mt: { xs: 0, md: '2vh', lg: "2vh" } }}>
                                            {t('crud.detail.breaks')}
                                        </Typography>
                                        <Stack sx={{
                                            mb: 1.5, width: { xs: '100%', md: '48%' },
                                        }}>
                                            {(data as Customer).workSchedule.workBreaks.slice().sort((a, b) => {
                                                const [aHour, aMinute] = a.startTime.split(':').map(Number);
                                                const [bHour, bMinute] = b.startTime.split(':').map(Number);

                                                const aTime = aHour * 60 + aMinute;
                                                const bTime = bHour * 60 + bMinute;

                                                return aTime - bTime;
                                            })?.map((breakDto) => (

                                                <TextField
                                                    defaultValue={`${breakDto.startTime} - ${breakDto.endTime} (${breakDto.paid ? "betaald" : "onbetaald"})`}
                                                    variant="outlined"
                                                    disabled
                                                    sx={{
                                                        mb: 1.5, width: "100%"
                                                        ,
                                                        "& .MuiInputBase-input.Mui-disabled": {
                                                            WebkitTextFillColor: "#000000",
                                                        }
                                                    }}
                                                />
                                            ))}
                                        </Stack>
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Card sx={{ minHeight: "30vh", backgroundColor: "#ECEBEA", boxShadow: 3 }}>
                                <CardContent>
                                    <Typography sx={{ textAlign: "center", fontWeight: "bold", mt: 1.5, marginBottom: "3vh", fontFamily: "Segoe UI", fontSize: "20px" }}>
                                        {t('crud.detail.job').toUpperCase()}
                                    </Typography>
                                    <Typography variant='h4'>
                                        <Stack
                                            direction={{ xs: 'column', md: 'row' }}
                                        >
                                            <Typography sx={{ fontWeight: "bold", width: { xs: '100%', md: '40%', lg: "25%" }, mt: { xs: 0, md: '2vh', lg: "2vh" } }}>
                                                {t('crud.detail.jobDescription')}
                                            </Typography>
                                            <TextField
                                                defaultValue={customer && (data as Customer)?.job.jobDescription}
                                                variant="outlined"
                                                disabled
                                                sx={{
                                                    mb: 1.5, width: { xs: '100%', md: '48%' },
                                                    "& .MuiInputBase-input.Mui-disabled": {
                                                        WebkitTextFillColor: "#000000",
                                                    }
                                                }}
                                            />
                                        </Stack>

                                        <Stack
                                            direction={{ xs: 'column', md: 'row' }}
                                        >
                                            <Typography sx={{ fontWeight: "bold", width: { xs: '100%', md: '40%', lg: "25%" }, mt: { xs: 0, md: '2vh', lg: "2vh" } }}>
                                                {t('crud.detail.numberOfEmployees')}
                                            </Typography>
                                            <TextField
                                                defaultValue={customer && (data as Customer)?.job.numberOfEmployees}
                                                variant="outlined"
                                                disabled
                                                sx={{
                                                    mb: 1.5, width: { xs: '100%', md: '48%' },
                                                    "& .MuiInputBase-input.Mui-disabled": {
                                                        WebkitTextFillColor: "#000000",
                                                    }
                                                }}
                                            />
                                        </Stack>

                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                }
                {(value == 1 && employee) && (
                    <>
                        {selectedJobAssignment && (
                            <JobAssignmentForm
                                key={selectedJobAssignment.jobAssignmentId}
                                jobAssignment={selectedJobAssignment}
                                onClose={() => setSelectedJobAssignment(null)}
                                employeeId={params.employeeId || ""}
                            />
                        )}
                        <Grid container sx={{ marginTop: "2%", display: "flex", flexDirection: "column" }}>
                            <Grid item style={{ width: "50%", alignSelf: "center" }}>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell width="30%" align='center'>{t('crud.fields.date')}</TableCell>
                                                <TableCell width="30%" align='center'>{t('crud.fields.customer')}</TableCell>
                                                <TableCell width="40%" align='center'>{t('crud.actions')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {(data as EmployeeDetail).jobAssignments?.map((jobassignment) => (
                                                <TableRow key={jobassignment.jobAssignmentId}>
                                                    <>
                                                        <TableCell width="30%" align='center'>{jobassignment.group.date}</TableCell>
                                                        <TableCell width="30%" align='center'>{jobassignment.group.customer.customerName}</TableCell>
                                                        <TableCell width="40%" align='center'>
                                                            <Link sx={{ cursor: "pointer" }} onClick={() => {
                                                                setSelectedJobAssignment(jobassignment)
                                                            }}>{t('crud.watch')}</Link>
                                                        </TableCell>
                                                    </>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
        </Grid >
    )
}

export default DetailPage


