export class CreateAddressDto {
    streetName: string;
    houseNumber: string;
    postboxNumber: string;
    city: string;
    postalCode: string;
    country: string;
    constructor(
        streetName: string,
        houseNumber: string,
        postboxNumber: string,
        city: string,
        postalCode: string,
        country: string
    ) {
        this.streetName = streetName;
        this.houseNumber = houseNumber;
        this.postboxNumber = postboxNumber;
        this.city = city;
        this.postalCode = postalCode;
        this.country = country
    }

    toJson() {
        return {
            streetName: this.streetName,
            houseNumber: this.houseNumber,
            postboxNumber: this.postboxNumber,
            city: this.city,
            postalCode: this.postalCode,
            country: this.country
        }
    }
}