import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "react-redux-typescript";
import { RootState } from "./ReduxStore";
import { Me } from "./Models/Me";

type AuthState = {
    me: Me | null;
};

const initialState: AuthState = {
    me: null,
};
const auth = createSlice({
    name: "auth",
    initialState,
    reducers: {
        authenticateState(state,action: PayloadAction<string, Me>) {
            state.me = action.payload;
        },
        logoutState(state) {
            state.me = null;
        },
    },
});


export const { authenticateState,logoutState} = auth.actions;
export default auth.reducer;

export const selectAuth = () => {
    return (state: RootState) => state.Auth.me;
};
