import { CreateAddressDto } from "./CreateAddressDto";
import { CreateContactInformationDto } from "./CreateContactInformationDto";

export class CreateActorDto {
    personId: string;
    firstName: string;
    lastName: string;
    address: CreateAddressDto;
    contactInformation: CreateContactInformationDto;
    constructor(
        personId: string,
        firstName: string,
        lastName: string,
        address: CreateAddressDto,
        contactInformation: CreateContactInformationDto,
    ) {
        this.personId = personId;
        this.firstName = firstName;
        this.lastName = lastName;
        this.contactInformation = contactInformation;
        this.address = address
    }

    toJSON() {
        const json: any = {
            firstName: this.firstName,
            lastName: this.lastName,
            address: this.address,
            contactInformation: this.contactInformation,
        };

        if (this.personId !== "") {
            json.personId = this.personId;
        }

        return json;
    }
}