import { TextField, Box } from "@mui/material";
import React from "react";
import { Customer } from "../../Models/Customer";
import { useTranslation } from "react-i18next";

const ContactPerson = ({ register, customer }: { register: any, customer?: Customer }) => {
    const { t } = useTranslation();

    return (
        <Box sx={{ marginTop: "2vh", display: "flex", flexDirection: "column" }}>
            <Box sx={{ width: { xs: "80vw", md: "50vw" }, alignSelf: "center" }}>
                {getTextField(t('crud.fields.firstName'), customer ? customer.contactPerson.firstName : "", { ...register("contactFirstName") }, 1.5, "100%", "49%", "2%")}
                {getTextField(t('crud.fields.lastName'), customer ? customer.contactPerson.lastName : "", { ...register("contactLastName") }, 1.5, "100%", "49%")}
                {getTextField(t('crud.fields.email'), customer ? customer.contactPerson.contactInformation.email : "", { ...register("contactEmail") }, 1.5, "100%", "100%")}
                {getTextField(t('crud.fields.telephoneNumber'), customer ? customer.contactPerson.contactInformation.telephoneNumber : "", { ...register("contactTelephonenumber") }, 1.5, "100%", "100%")}
                <input
                    type="hidden"
                    defaultValue={customer?.contactPerson.personId || ""}
                    {...register(`contactPersonId`)}
                />
            </Box>
        </Box>
    );
};

export default ContactPerson;

function getTextField(label: string, defaultValue: string | number, register: any, mb: number = 1.5, widthXs: string = "100%", widthSm: string = "32%", mr?: string, height?: string) {
    return (
        <TextField
            label={label}
            defaultValue={defaultValue}
            variant="outlined"
            sx={{ mb: mb, width: { xs: widthXs, sm: widthSm }, mr: mr ? mr : "0%", height: height }}
            {...register}
        />
    );
}
