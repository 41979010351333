import { CreateAddressDto } from "./CreateAddressDto";
import { CreateContactPersonDto } from "./CreateContactPersonDto";
import { CreateJobDto } from "./CreateJobDto";
import { CreateWorkScheduleDto } from "./CreateWorkSchedule";

export class CreateCustomerDto {
    customerId: string;
    customerName: string;
    contactPerson: CreateContactPersonDto;
    workSchedule: CreateWorkScheduleDto;
    job: CreateJobDto;
    address: CreateAddressDto;

    constructor(
        customerId: string,
        customerName: string,
        contactPerson: CreateContactPersonDto,
        workSchedule: CreateWorkScheduleDto,
        job: CreateJobDto,
        address: CreateAddressDto,
    ) {
        this.customerId = customerId;
        this.customerName = customerName;
        this.contactPerson = contactPerson;
        this.workSchedule = workSchedule;
        this.job = job;
        this.address = address;
    }

    toJSON() {
        const json: any = {
            customerName: this.customerName,
            contactPerson: this.contactPerson.toJson(),
            workSchedule: this.workSchedule.toJson(),
            job: this.job.toJson(),
            address: this.address.toJson(),
        };

        if (this.customerId !== "") {
            json.customerId = this.customerId;
        }

        return json;
    }
}