import React, { useEffect } from 'react';
import WeekPicker from '../../Components/WeekPicker';
import dayjs, { Dayjs } from "dayjs";
import { getAllCustomers } from '../../Services/DataService';
import { Customer } from '../../Models/Customer';
import { useQuery } from '@tanstack/react-query'
import { Grid, List, ListItem, ListItemText, ListItemButton, CircularProgress, Divider } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

function ChooseCustomerAndWeek() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedDate, setSelectedDate] = React.useState<string | null>(null);
    const [weekPickerValue, setWeekPickerValue] = React.useState<Dayjs | null>(null);

    useEffect(() => {
        if (location.state?.currentWeek) {
            setSelectedDate(dayjs().startOf('week').add(1, 'day').format('DD-MM-YYYY'));
            setWeekPickerValue(dayjs(dayjs().format('YYYY-MM-DD')));
        } else {
            const selectedWeek = sessionStorage.getItem('selectedWeek');
            if (selectedWeek) {
                setSelectedDate(selectedWeek);
                setWeekPickerValue(dayjs(selectedWeek, 'DD-MM-YYYY'));
            } else {
                setSelectedDate(dayjs().startOf('week').add(1, 'day').format('DD-MM-YYYY'));
                setWeekPickerValue(dayjs(dayjs().format('YYYY-MM-DD')));
            }
        }
    }, [location.state]);

    const { isLoading: customersLoading, data: customers } = useQuery<Customer[]>(['allCustomers'], getAllCustomers, {
        staleTime: 300000, retry: 0,
        onError: (error: any) => {
            error.response.status == 403 ?
                navigate("/login") :
                console.log(error.response.data);
        }
    });

    if (customersLoading) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '80vh'
                }}
            >
                <CircularProgress />
            </div>
        );
    }

    if (!customers || customers.length === 0) {
        return (
            <Grid container>
                <Grid item xs={9}>
                    <p>{t('customer.notFoundAll')}</p>
                </Grid>

            </Grid >
        );
    }

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
        >
            <Grid item>
                <WeekPicker
                    selectedWeek={setSelectedDate}
                    value={weekPickerValue}
                    onValueChange={setWeekPickerValue}
                />
            </Grid>
            <Grid item sx={{
                width: {
                    xs: '70vw',
                    sm: '60vw',
                    md: '35vw',
                }
            }}>
                <List sx={{ width: '100%', bgcolor: "white", boxShadow: "5", borderRadius: 3, padding: 0 }}>
                    {customers?.map((d, index) => (
                        <>
                            <ListItem key={d.customerId}>
                                <ListItemButton onClick={() => navigate(`/timetable/${d.customerId}/${selectedDate}/new`, { state: { customerName: d.customerName, numberOfEmployeesNeeded: d.job.numberOfEmployees } })}>
                                    <ListItemText primary={d?.customerName} secondary={d.job.jobDescription} />
                                    <ListItemText secondary={`${d.job.numberOfEmployees} ${t('customers.customers_lowercase')}`} sx={{ textAlign: "right" }} />
                                </ListItemButton>
                            </ListItem>
                            {index !== customers.length - 1 && <Divider />}
                        </>
                    ))}
                </List>
            </Grid>
        </Grid >
    )
}

export default ChooseCustomerAndWeek

