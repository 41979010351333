import React, {ReactNode} from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectAuth, authenticateState } from "../../Reducers/Auth";
import axios from "axios";
interface ProtectedRouteProps {
    isAllowed: boolean;
    redirectPath?: string;
    children?: ReactNode;
}
const ProtectedRoute = ({
    isAllowed,
    redirectPath = '/not-authorized',
    children,
}:ProtectedRouteProps) => {
    const user = useSelector(selectAuth())
    const dispatch = useDispatch();
    if (!user) {
        axios.get("/auth/me").then((response) => {
            dispatch(authenticateState(response.data));
        }).catch((error) => {
            return <Navigate to={"/login"} replace />
        })
    } else {
        if (!isAllowed) {
            return <Navigate to={redirectPath} replace />;
        }
    }
    return children ? children : <Outlet />;
};

export default ProtectedRoute;
