import React from 'react';
import { Grid, Tab, Tabs } from '@mui/material';
import NewAbsence from '../Components/NewAbsence';
import LookupAbsences from '../Components/LookupAbsences';

const Absences = () => {
    const [value, setValue] = React.useState(0);
    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    return (
        <>
            <Grid container sx={{ mt: "5vh" }}>
                <Grid item xs={12} sx={{ backgroundColor: "#EE7223", mb: "2.5vh" }} >
                    <Tabs value={value} onChange={handleChangeTab} TabIndicatorProps={{ style: { backgroundColor: "transparent" } }}
                        sx={{
                            '& .MuiTabs-flexContainer': {
                                flexWrap: 'wrap',
                            },
                        }}
                    >
                        <Tab label={"Nieuwe afwezigheid"} style={{
                            backgroundColor: value == 0 ? "#C6332F" : "",
                            color: "white",
                        }}
                        />
                        <Tab label={"Afwezigheden"} style={{
                            backgroundColor: value == 1 ? "#C6332F" : "",
                            color: "white",
                        }}
                        />
                    </Tabs>
                </Grid>
                {value == 0 &&
                    <Grid item container justifyContent="center">
                        <NewAbsence />
                    </Grid>
                }
                {value == 1 &&
                    <LookupAbsences />
                }
            </Grid >
        </>
    );
}

export default Absences;