export class CreateWorkScheduleDto {
    workScheduleId: string;
    startTime: string;
    endTime: string;
    workBreaks: CreateWorkBreakDto[];
    constructor(
        workScheduleId: string,
        startTime: string,
        endTime: string,
        workBreaks: CreateWorkBreakDto[],
    ) {
        this.workScheduleId = workScheduleId;
        this.startTime = startTime;
        this.endTime = endTime;
        this.workBreaks = workBreaks;
    }

    toJson() {
        const json: any = {
            startTime: this.startTime,
            endTime: this.endTime,
            workBreaks: this.workBreaks?.map(wb => { return wb.toJson() }),
        };

        if (this.workScheduleId !== "") {
            json.workScheduleId = this.workScheduleId;
        }

        return json;
    }
}

export class CreateWorkBreakDto {
    breakId: string;
    startTime: string;
    endTime: string;
    paid: boolean;
    constructor(
        breakId: string,
        startTime: string,
        endTime: string,
        paid: boolean,
    ) {
        this.breakId = breakId;
        this.startTime = startTime;
        this.endTime = endTime;
        this.paid = paid;
    }

    toJson() {
        const json: any = {
            startTime: this.startTime,
            endTime: this.endTime,
            paid: this.paid,
        };

        if (this.breakId !== "") {
            json.breakId = this.breakId;
        }

        return json;

    }
}
