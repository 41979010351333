import React from 'react';
import './index.css';
import App from './App.tsx';
import reportWebVitals from './reportWebVitals';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { createRoot } from "react-dom/client"
import { Provider } from 'react-redux';
import { store } from './Reducers/ReduxStore.ts';
import "./translations/config.js"
const queryClient = new QueryClient()

createRoot(
  document.getElementById("root")
).render
  (
    <React.StrictMode>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <App />
          <ReactQueryDevtools />
        </QueryClientProvider>
      </Provider>
    </React.StrictMode>
  )


reportWebVitals();

