import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { createCustomerWage, getAllCustomers, getAllEmployees } from '../Services/DataService';
import { Customer } from '../Models/Customer';
import { useQuery } from '@tanstack/react-query'
import { CoachEmployee } from '../Models/CoachEmployee';
import { FormControl, InputLabel, MenuItem, Select, Switch, TextField } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { CircularProgress } from '@mui/material';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useNavigate } from "react-router-dom";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

export default function NewCustomerPriceModal({ open, handleClose }: { open: any, handleClose: any }) {
    const { t } = useTranslation();
    const [customer, setCustomer] = React.useState('');
    const [employee, setEmployee] = React.useState('');
    const [checked, setChecked] = React.useState<boolean>(true);
    const [error, setError] = React.useState<boolean>(false);
    const querycl = useQueryClient();
    const navigate = useNavigate();

    const schema = yup.object().shape({
        hourly_rate: yup.number()
            .typeError(t('crud.exceptions.type_number'))
            .transform((value, originalValue) =>
                originalValue.trim() === '' ? null : value
            )
            .nullable()
            .required(t('crud.exceptions.hourlyWage')),
        customer: yup.string().required('Please select a customer.'),
        employee: yup.string().test(
            'employee-required',
            'Please select an employee.ddd',
            function (value) {
                // Use the 'checked' variable from the closure
                return checked ? true : !!value;
            }
        ),
    });

    const { handleSubmit, register, reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema,)
    });

    const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };
    const handleChangeCustomer = (event: SelectChangeEvent) => {
        setCustomer(event.target.value as string);
    };
    const handleChangeEmployee = (event: SelectChangeEvent) => {
        setEmployee(event.target.value as string);
    };

    const { isLoading: customersLoading, data: customers } = useQuery<Customer[]>(
        ['allCustomers'],
        () => getAllCustomers(), {
        staleTime: 300000,
        retry: false,
        onError(error: any) {
            error.response.status == 403 ? navigate('/login') : setError(true);
        }
    }
    );
    const { isLoading: employeesLoading, data: employees } = useQuery<CoachEmployee[]>(
        ['allEmployees'],
        () => getAllEmployees(), {
        staleTime: 300000,
        retry: false,
        onError(error: any) {
            error.response.status == 403 ? navigate('/login') : setError(true);
        }
    }
    );

    const onSubmit = (data) => {
        const toSubmit = {
            type: checked ? "FIXED" : "VARIABLE",
            customerId: customer,
            employeeId: employee ? employee : null,
            price: data.hourly_rate
        }
        mutate(toSubmit);
    };

    const { mutate, isLoading } = useMutation(
        (newCustomerPrice: any) => createCustomerWage(newCustomerPrice)
        , {
            onSuccess: () => {
                querycl.removeQueries(["customerWages"]);
                handleClose();
            },
            onError: (error: any) => {
                error.response.status == 403 ? navigate('/login') : setError(true);
            }
        });

    if (customersLoading || employeesLoading || isLoading) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '80vh'
                }}
            >
                <CircularProgress />
            </div>
        );
    }

    return (
        <div>
            <Modal open={open} onClose={handleClose}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box sx={style}>
                        <Typography variant="h6" component="h2">
                            Prijs voor klant toevoegen
                        </Typography>
                        <FormControl fullWidth>
                            <InputLabel>Klant</InputLabel>
                            <Select
                                value={customer}
                                label="Klant"
                                error={Boolean(errors.customer)}
                                {...register("customer")}
                                onChange={handleChangeCustomer}
                            >
                                {customers?.map((customer) => (
                                    <MenuItem key={customer.customerId} value={customer.customerId}>
                                        {customer.customerName}
                                    </MenuItem>
                                ))}
                            </Select>

                            <label htmlFor="custom-switch">
                                <Switch
                                    checked={checked}
                                    onChange={handleChangeSwitch}
                                />
                                {checked ? "Vaste prijs" : "Variabele prijs per werknemer"}
                            </label>
                            {checked && <TextField label="Uurprijs" variant="outlined" {...register("hourly_rate")} error={Boolean(errors.hourly_rate)} />}
                            {!checked &&
                                <FormControl fullWidth>
                                    <InputLabel>Werknemer</InputLabel>
                                    <Select
                                        value={employee}
                                        label="Doelgroepmedewerker"
                                        error={Boolean(errors.employee)}
                                        {...register("employee")}
                                        onChange={handleChangeEmployee}
                                    >
                                        {employees?.map((employee) => {
                                            return <MenuItem value={employee.personId} key={employee.personId}>{employee.firstName}</MenuItem>

                                        })}
                                    </Select>
                                    <TextField label="Uurprijs" variant="outlined"
                                        {...register("hourly_rate")} error={Boolean(errors.hourly_rate)} />
                                </FormControl>
                            }
                            <Button variant="contained" color="primary" type='submit'>Opslaan</Button>
                        </FormControl>
                        {Object.values(errors).some((error) => error) && (
                            <>
                                {Object.entries(errors).map(([key, value]) => (
                                    value && (
                                        <div key={key} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <div style={{ display: 'flex', textAlign: "left", alignItems: 'center', width: "25vw" }}>
                                                <ErrorOutlineIcon style={{ fill: '#FF2D00' }} />
                                                <p style={{ marginLeft: '2px' }}>{value?.message}</p>
                                            </div>
                                        </div>
                                    )
                                ))}
                            </>
                        )}
                        {error &&
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div style={{ display: 'flex', textAlign: "left", alignItems: 'center', width: "25vw" }}>
                                    <ErrorOutlineIcon style={{ fill: '#FF2D00' }} />
                                    <p style={{ marginLeft: '2px' }}>Er liep iets fout...</p>
                                </div>
                            </div>
                        }
                    </Box>
                </form>
            </Modal>
        </div>
    );
}
