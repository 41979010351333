import React from "react";
import { TextField, Box } from "@mui/material";
import { Customer } from "../../Models/Customer";
import { useTranslation } from "react-i18next";

const GeneralInfo = ({ register, customer }: { register: any, customer?: Customer }) => {
    const { t } = useTranslation();

    return (
        <Box sx={{ marginTop: "2vh", display: "flex", flexDirection: "column" }}>
            <Box sx={{ width: { xs: "80vw", md: "50vw" }, alignSelf: "center" }}>
                {getTextField(t('crud.fields.customerName'), customer ? customer.customerName : "", { ...register("customerName") }, 1.5, "100%", "48%", "2%")}
                {getTextField(t('crud.fields.number_of_employees'), customer ? customer.job.numberOfEmployees : "", { ...register("numberOfEmployees") }, 1.5, "100%", "48%")}
                {getTextField(t('crud.fields.jobDescription'), customer ? customer.job.jobDescription : "", { ...register("jobDescription") }, 1.5, "98%", "98%")}

            </Box>
        </Box>
    );
};

export default GeneralInfo;

function getTextField(label: string, defaultValue: string | number, register: any, mb: number = 1.5, widthXs: string = "100%", widthSm: string = "32%", mr?: string, height?: string) {
    return (
        <TextField
            label={label}
            defaultValue={defaultValue}
            variant="outlined"
            sx={{ mb: mb, width: { xs: widthXs, sm: widthSm }, mr: mr ? mr : "0%", height: height }}
            {...register}
        />
    );
}