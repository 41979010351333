import * as React from "react";
import Box from "@mui/material/Box";
import Navbar from "./Components/Navbar";
import OverviewPage from "./Pages/OverviewPage"
import {BrowserRouter as Router, Routes, Route, useParams} from 'react-router-dom';
import axios from "axios";
import AddEditPage from "./Pages/AddEditPage";
import DetailPage from "./Pages/DetailPage";
import ChooseCustomerAndWeek from "./Pages/TimeTable/ChooseCustomerAndWeek";
import CreateTimetable from "./Pages/TimeTable/CreateTimetable";
import ViewTimetable from "./Pages/TimeTable/ViewTimetable";
import Reports from "./Pages/Reports";
import Login from "./Pages/Auth/Login";
import ProtectedRoute from "./Pages/Auth/ProtectedRoute";
import {useSelector} from "react-redux";
import {selectAuth} from "./Reducers/Auth";
import NotFound from "./Pages/NotFound";
import ChangePassword from "./Pages/Auth/ChangePassword";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import Absences from "./Pages/Absences";
axios.defaults.baseURL = process.env.REACT_APP_API_URL;


function App() {
    const user = useSelector(selectAuth())

    const theme = createTheme({
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        backgroundColor: "#facd69",
                        color: "#000000",
                        fontWeight: 'bold',
                        '&:hover': {
                            backgroundColor: '#fff',
                            color: 'black',
                            fontWeight: 'bold',
                        }
                    },
                },
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        '&.Mui-focused': {
                            color: '#000000',
                        },
                        '&.MuiInputLabel-shrink': {
                            color: '#000000',
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#facd69',
                        },
                    },
                },
            },
            MuiStepIcon: {
                styleOverrides: {
                    root: {
                        '&.Mui-completed': {
                            color: '#facd69',
                        },
                        '&.Mui-active': {
                            color: '#facd69',
                        },
                    },
                },
            },
            MuiTypography: {
                styleOverrides: {
                    root: {
                        fontFamily: 'Verdana',
                    },
                },
            },
        },
    });

    const CoachEditWrapper = () => {
        const {coachId} = useParams();
        const isAllowed = !!user && (user.role === "ROLE_ADMIN" || user.userId.toString() === coachId);

        return (
            <ProtectedRoute
                redirectPath={!user ? "/login" : ((user && user.role !== "ROLE_ADMIN") ? "/not-authorized" : "/not-authorized")}
                isAllowed={isAllowed}
            >
                <AddEditPage edit={true} employee={false} coach={true} customer={false}/>
            </ProtectedRoute>
        );
    };

    return (

        <Router>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "100vh"
                }}
            >
                <Navbar user={user}/>
                <ThemeProvider theme={theme}>
                    <Box
                        component="main"
                        sx={{
                            flexGrow: 1,
                            p: 5,
                            marginTop: "13vh",
                        }}
                    >
                        <Routes>
                            <Route path="/login" element={<Login/>}/>
                            <Route
                                path="/change_password"
                                element={
                                    <ProtectedRoute
                                        redirectPath="/login"
                                        isAllowed={
                                            !!user
                                        }
                                    >
                                        <ChangePassword/>
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/absences"
                                element={
                                    <ProtectedRoute
                                        redirectPath="/"
                                        isAllowed={
                                            !!user
                                        }
                                    >
                                        <Absences/>
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/reports"
                                element={
                                    <ProtectedRoute
                                        redirectPath={!user ? "/login" : ((user && user.role != "ROLE_ADMIN") ? "/not-authorized" : "/not-authorized")}
                                        isAllowed={
                                            !!user && user.role == "ROLE_ADMIN"
                                        }
                                    >
                                        <Reports/>
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/timetable"
                                element={
                                    <ProtectedRoute
                                        redirectPath="/login"
                                        isAllowed={
                                            !!user
                                        }
                                    >
                                        <ViewTimetable user={user}/>
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/timetable/create"
                                element={
                                    <ProtectedRoute
                                        redirectPath={!user ? "/login" : ((user && user.role != "ROLE_ADMIN") ? "/not-authorized" : "/not-authorized")}
                                        isAllowed={
                                            !!user && user.role == "ROLE_ADMIN"
                                        }
                                    >
                                        <ChooseCustomerAndWeek/>
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/timetable/:customerId/:firstDayOfWeek/new"
                                element={
                                    <ProtectedRoute
                                        redirectPath={!user ? "/login" : ((user && user.role != "ROLE_ADMIN") ? "/not-authorized" : "/not-authorized")}
                                        isAllowed={
                                            !!user && user.role == "ROLE_ADMIN"
                                        }
                                    >
                                        <CreateTimetable/>
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/timetable/:customerId/:firstDayOfWeek/edit"
                                element={
                                    <ProtectedRoute
                                        redirectPath={!user ? "/login" : ((user && user.role != "ROLE_ADMIN") ? "/not-authorized" : "/not-authorized")}
                                        isAllowed={
                                            !!user && user.role == "ROLE_ADMIN"
                                        }
                                    >
                                        <CreateTimetable/>
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/employees"
                                element={
                                    <ProtectedRoute
                                        redirectPath="/login"
                                        isAllowed={
                                            !!user
                                        }
                                    >
                                        <OverviewPage employee={true} coach={false} customer={false} user={user}/>
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/employees/:employeeId"
                                element={
                                    <ProtectedRoute
                                        redirectPath="/login"
                                        isAllowed={
                                            !!user
                                        }
                                    >
                                        <DetailPage coach={false} employee={true} customer={false}/>
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/employees/:employeeId/edit"
                                element={
                                    <ProtectedRoute
                                        redirectPath={!user ? "/login" : ((user && user.role != "ROLE_ADMIN") ? "/not-authorized" : "/not-authorized")}
                                        isAllowed={
                                            !!user && user.role == "ROLE_ADMIN"
                                        }
                                    >
                                        <AddEditPage edit={true} employee={true} coach={false} customer={false}/>
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/employees/add"
                                element={
                                    <ProtectedRoute
                                        redirectPath={!user ? "/login" : ((user && user.role != "ROLE_ADMIN") ? "/not-authorized" : "/not-authorized")}
                                        isAllowed={
                                            !!user && user.role == "ROLE_ADMIN"
                                        }
                                    >
                                        <AddEditPage edit={false} employee={true} coach={false} customer={false}/>
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/customers"
                                element={
                                    <ProtectedRoute
                                        redirectPath="/login"
                                        isAllowed={
                                            !!user
                                        }
                                    >
                                        <OverviewPage employee={false} coach={false} customer={true} user={user}/>
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/customers/add"
                                element={
                                    <ProtectedRoute
                                        redirectPath={!user ? "/login" : ((user && user.role != "ROLE_ADMIN") ? "/not-authorized" : "/not-authorized")}
                                        isAllowed={
                                            !!user && user.role == "ROLE_ADMIN"
                                        }
                                    >
                                        <AddEditPage edit={false} employee={false} coach={false} customer={true}/>
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/customers/:customerId"
                                element={
                                    <ProtectedRoute
                                        redirectPath="/login"
                                        isAllowed={
                                            !!user
                                        }
                                    >
                                        <DetailPage coach={false} employee={false} customer={true}/>
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/customers/:customerId/edit"
                                element={
                                    <ProtectedRoute
                                        redirectPath={!user ? "/login" : ((user && user.role != "ROLE_ADMIN") ? "/not-authorized" : "/not-authorized")}
                                        isAllowed={
                                            !!user && user.role == "ROLE_ADMIN"
                                        }
                                    >
                                        <AddEditPage edit={true} employee={false} coach={false} customer={true}/>
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/coaches"
                                element={
                                    <ProtectedRoute
                                        redirectPath="/login"
                                        isAllowed={
                                            !!user
                                        }
                                    >
                                        <OverviewPage employee={false} coach={true} customer={false} user={user}/>
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/coaches/add"
                                element={
                                    <ProtectedRoute
                                        redirectPath={!user ? "/login" : ((user && user.role != "ROLE_ADMIN") ? "/not-authorized" : "/not-authorized")}
                                        isAllowed={
                                            !!user && user.role == "ROLE_ADMIN"
                                        }
                                    >
                                        <AddEditPage edit={false} employee={false} coach={true} customer={false}/>
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/coaches/:coachId"
                                element={
                                    <ProtectedRoute
                                        redirectPath="/login"
                                        isAllowed={
                                            !!user
                                        }
                                    >
                                        <DetailPage coach={true} employee={false} customer={false}/>
                                    </ProtectedRoute>
                                }
                            />
                            <Route path="/coaches/:coachId/edit" element={<CoachEditWrapper/>}/>
                            <Route
                                path="/"
                                element={
                                    <ProtectedRoute
                                        redirectPath="/login"
                                        isAllowed={
                                            !!user
                                        }
                                    >
                                        <ViewTimetable user={user}/>
                                    </ProtectedRoute>
                                }
                            />
                            <Route path="/*" element={<NotFound/>}/>

                        </Routes>
                    </Box>
                </ThemeProvider>
            </Box>
        </Router>
    );
}

export default App;

