import axios from "axios";
import { Customer } from "../Models/Customer"
import { CoachEmployee } from "../Models/CoachEmployee"
import { Schedule } from "../Reducers/EmployeePlanning";
import { Timetable } from "../Models/dto/Timetable";
import { EmployeeDetail } from "../Models/EmployeeDetail";
import { CustomerPrice } from "../Models/CustomerPrice";
import { store } from "../Reducers/ReduxStore"

export const getAllEmployees = async () => {
    const user = store.getState().Auth;

    const response = await axios.get<CoachEmployee[]>('/employees', {
        headers: {
            'Authorization': user?.me?.token
        }
    });
    return response.data;
}

export const getEmployee = async (employeeId: string) => {
    const user = store.getState().Auth;

    const response = await axios.get<EmployeeDetail>(`/employees/${employeeId}/details`, {
        headers: {
            'Authorization': user?.me?.token
        }
    });
    return response.data;
}

export const getEmployeeNoDetails = async (employeeId: string) => {
    const user = store.getState().Auth;

    const response = await axios.get<CoachEmployee>(`/employees/${employeeId}`, {
        headers: {
            'Authorization': user?.me?.token
        }
    });
    return response.data;
}

export const createEmployee = async (data: any) => {
    const user = store.getState().Auth;

    const { data: response } = await axios.post('/employees', data, {
        headers: {
            'Authorization': user?.me?.token
        }
    });
    return response.data;
};

export const updateEmployee = async (data: any) => {
    const user = store.getState().Auth;

    const { data: response } = await axios.put('/employees', data, {
        headers: {
            'Authorization': user?.me?.token
        }
    });
    return response.data;
};

export const deleteEmployee = async (userId: string) => {
    const user = store.getState().Auth;

    const { data: response } = await axios.delete(`/employees/${userId}`, {
        headers: {
            'Authorization': user?.me?.token
        }
    });
    return response.data;
};

export const getAllCustomers = async () => {
    const user = store.getState().Auth;

    const response = await axios.get<Customer[]>('/customers', {
        headers: {
            'Authorization': user?.me?.token
        }
    });
    return response.data;
}

export const getCustomer = async (customerId: string) => {
    const user = store.getState().Auth;

    const response = await axios.get<Customer>("/customers/".concat(customerId), {
        headers: {
            'Authorization': user?.me?.token
        }
    });
    return response.data;
}

export const createCustomer = async (data: any) => {
    const user = store.getState().Auth;

    const { data: response } = await axios.post('/customers', data, {
        headers: {
            'Authorization': user?.me?.token
        }
    });
    return response.data;
};

export const updateCustomer = async (data: any) => {
    const user = store.getState().Auth;

    const { data: response } = await axios.put('/customers', data, {
        headers: {
            'Authorization': user?.me?.token
        }
    });
    return response.data;
};

export const deleteCustomer = async (customerId: string) => {
    const user = store.getState().Auth;

    const { data: response } = await axios.delete(`/customers/${customerId}`, {
        headers: {
            'Authorization': user?.me?.token
        }
    });
    return response.data;
};

export const getAllCoaches = async () => {
    const user = store.getState().Auth;

    const response = await axios.get<CoachEmployee[]>('/coaches', {
        headers: {
            'Authorization': user?.me?.token
        }
    });
    return response.data;
}

export const getCoach = async (coachId: string) => {
    const user = store.getState().Auth;

    const response = await axios.get<CoachEmployee>("/coaches/".concat(coachId), {
        headers: {
            'Authorization': user?.me?.token
        }
    });
    return response.data;
}

export const createCoach = async (data: any) => {
    const user = store.getState().Auth;

    const { data: response } = await axios.post('/coaches', data, {
        headers: {
            'Authorization': user?.me?.token
        }
    });
    return response.data;
};

export const updateCoach = async (data: any) => {
    const user = store.getState().Auth;

    const { data: response } = await axios.put('/coaches', data, {
        headers: {
            'Authorization': user?.me?.token
        }
    });
    return response.data;
};

export const deleteCoach = async (userId: string) => {
    const user = store.getState().Auth;

    const { data: response } = await axios.delete(`/coaches/${userId}`, {
        headers: {
            'Authorization': user?.me?.token
        }
    });
    return response.data;
};

export const getAssignableAndNonAssignableEmployeesForThisWeek = async (firstDayOfWeek: string) => {
    const user = store.getState().Auth;

    const response = await axios.get<Schedule>(`/timetable/employees/status/${firstDayOfWeek}`, {
            headers: {
                'Authorization': user?.me?.token
            }
        });

    return response.data;
};

export const getAssignableAndNonAssignableCoachesForThisWeek = async (firstDayOfWeek: string) => {
    const user = store.getState().Auth;

    const response = await axios.get<Schedule>(`/timetable/coaches/status/${firstDayOfWeek}`, {
        headers: {
            'Authorization': user?.me?.token
        }
    });
    return response.data;
};

export const createEmployeePlanning = async (data: any, id: any) => {
    const user = store.getState().Auth;

    const jsonData = mapToObject(data);
    const { data: response } = await axios.post(`/timetable/${id}/employees`, jsonData, {
        headers: {
            'Authorization': user?.me?.token
        }
    });
    return response.data;
};

export const createCoachPlanning = async (data: any, id: any) => {
    const user = store.getState().Auth;

    const jsonData = mapToObject(data);
    const { data: response } = await axios.post(`/timetable/${id}/coaches`, jsonData, {
        headers: {
            'Authorization': user?.me?.token
        }
    });
    return response.data;
};

export const getTimetableForWeek = async (data: string) => {
    const user = store.getState().Auth;

    const { data: response } = await axios.get<Timetable>(`/timetable/${data}`, {
        headers: {
            'Authorization': user?.me?.token
        }
    });
    return response;
};

export const getCustomerId = async (data: string) => {
    const user = store.getState().Auth;

    const { data: response } = await axios.get<string>(`/customers/find/${data}`, {
        headers: {
            'Authorization': user?.me?.token
        }
    });
    return response;
};

export const createDayReport = async ({ reportData, employeeId, jobAssignmentId }: {
    reportData: {
        comments: string;
        startTimeEmployee: string;
        endTimeEmployee: string;
        modeOfTransport: string;
        notProducedNotPaid: {
            reason: string;
            numberOfHours: number;
        }
        notProducedPaid: {
            reason: string;
            numberOfHours: number;
        }
    },
    employeeId: string,
    jobAssignmentId: string
}) => {
    const user = store.getState().Auth;

    const { data: response } = await axios.post(`/employees/${employeeId}/jobAssignments/${jobAssignmentId}/dayreport`, reportData, {
        headers: {
            'Authorization': user?.me?.token
        }
    });
    return response.data;
};

const mapToObject = (map: Map<any, any>) => {
    const outObject: Record<string, any> = {};
    map.forEach((value, key) => {
        outObject[key] = value;
    });
    return outObject;
};

export const getInvoiceForClientForPeriod = async (customer:Customer|undefined, dayFrom:string, dateUntil:string) => {
    const user = store.getState().Auth;

    try {
        const response = await axios.get(`/customers/${customer?.customerId}/invoice?dayFrom=${dayFrom}&dateUntil=${dateUntil}`, {
            responseType: 'blob',
            headers: {
                'Authorization': user?.me?.token
            }
        });

        // Extract filename from Content-Disposition header if present
        const contentDisposition = response.headers['content-disposition'];
        let filename = `Voorbereiding_factuur_${customer?.customerName}.xls`;
        if (contentDisposition) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(contentDisposition);
            if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
            }
        }

        // Create a Blob from the response data
        const file = new Blob(
            [response.data],
            { type: 'application/vnd.ms-excel' }
        );

        // Build a URL from the file
        const fileURL = URL.createObjectURL(file);

        // Create a temporary anchor element and trigger download
        const link = document.createElement('a');
        link.href = fileURL;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();

        // Cleanup
        URL.revokeObjectURL(fileURL);
        link.remove();
    } catch (error) {
        console.error('Error downloading file:', error);
    }
};

export const getWagesForCustomers = async () => {
    const user = store.getState().Auth;

    const { data: response } = await axios.get(`/invoices/customer_wages`, {
        headers: {
            'Authorization': user?.me?.token
        }
    });
    const wagesMap: Map<string, CustomerPrice[]> = new Map();
    Object.keys(response).forEach(key => {
        wagesMap.set(key, response[key]);
    });
    return wagesMap;
};

export const createCustomerWage = async (data: any) => {
    const user = store.getState().Auth;

    const { data: response } = await axios.post('/invoices/customer_wages', data, {
        headers: {
            'Authorization': user?.me?.token
        }
    });
    return response.data;
};

export const authenticate = async (data: any) => {
    const { data: response } = await axios.post('/auth/login', data);
    return response;
};


export const changePassword = async (data: any) => {
    const { data: response } = await axios.post('/auth/changepassword', data);
    return response;
};

export const createAbsence = async (data: any) => {
    const user = store.getState().Auth;

    const { data: response } = await axios.post('/absences', data, {
        headers: {
            'Authorization': user?.me?.token
        }
    });
    return response.data;
};

export const getAbsencesForPeriod = async (fromDate: string, untilDate:string) => {
    const user = store.getState().Auth;

    const { data: response } = await axios.get('/absences', {
        params: {
            from: fromDate,
            until: untilDate
        },
        headers: {
            'Authorization': user?.me?.token
        }
    });
    return response;
};

export const deleteAbsence = async (absenceId: string) => {
    const user = store.getState().Auth;

    const { data: response } = await axios.delete(`/absences/${absenceId}`, {
        headers: {
            'Authorization': user?.me?.token
        }
    });
    return response.data;
};
