import React, {useEffect} from "react";
import { TextField, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CoachEmployee } from "../../Models/CoachEmployee";

const PersonalInformation = ({ register, actor }: { register: any, actor?: CoachEmployee }) => {
    const { t } = useTranslation();
    console.log(actor)

    useEffect(() => {

    }, [actor]);

    return (
        <Box sx={{ marginTop: "2vh", display: "flex", flexDirection: "column" }}>
            <Box sx={{ width: { xs: "80vw", md: "50vw" }, alignSelf: "center" }}>
                {getTextField(t('crud.fields.lastName'), actor ? actor.lastName : "", { ...register("lastName") }, 1.5, "100%", "48%", "2%")}
                {getTextField(t('crud.fields.firstName'), actor ? actor.firstName : "", { ...register("firstName") }, 1.5, "100%", "48%")}
                {getTextField(t('crud.fields.email'), actor ? actor.contactInformation.email : "", { ...register("email") }, 1.5, "100%", "48%", "2%")}
                {getTextField(t('crud.fields.telephoneNumber'), actor ? actor.contactInformation.telephoneNumber : "", { ...register("telephoneNumber") }, 1.5, "100%", "48%")}
            </Box>
        </Box>
    );
};

export default PersonalInformation;

function getTextField(label: string, defaultValue: string | number, register: any, mb: number = 1.5, widthXs: string = "100%", widthSm: string = "32%", mr?: string, height?: string) {
    return (
        <TextField
            label={label}
            defaultValue={defaultValue}
            variant="outlined"
            sx={{ mb: mb, width: { xs: widthXs, sm: widthSm }, mr: mr ? mr : "0%", height: height }}
            {...register}
        />
    );
}
