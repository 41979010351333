import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "react-redux-typescript";
import { RootState } from "./ReduxStore";
import { Person, PersonWithCustomer, PersonWithCustomerAndDate } from "./Models/Person";


export interface Schedule {
    available: { [key: string]: Person[] };
    nonAvailable: { [key: string]: PersonWithCustomer[] };
}

type ScheduleState = {
    schedule: Schedule;
};
const initialState: ScheduleState = {
    schedule: { available: {}, nonAvailable: {} },
};
const employeePlanning = createSlice({
    name: "employeePlanning",
    initialState,
    reducers: {
        setAvailableAndNonAvailableEmployees(
            state,
            action: PayloadAction<string, Schedule>
        ) {
            state.schedule = action.payload;
        },
        emptyAvailableAndNonAvailableEmployees(state) {
            state.schedule = { available: {}, nonAvailable: {} };
        },

        setEmployeeAsNonAvailable(state, action: PayloadAction<string, PersonWithCustomerAndDate>) {
            //HERE CONVERT TO PERSON WITH CUSTOMER

            const payload = action.payload;
            const date = payload.date || "";
            const available = state.schedule.available[date] || [];
            const nonAvailable = state.schedule.nonAvailable[date] || [];

            // Filter out the person from the available array
            const updatedAvailable = available.filter(
                person => person.personId !== payload.personId
            );

            const newNonAvailable = {
                personId: payload.personId,
                firstName: payload.firstName,
                lastName: payload.lastName,
                groupId: null,
                customerId: payload.customerId,
                customerName: payload.customerName,
            }

            // Assuming payload already contains a person object that should be added to nonAvailable
            const updatedNonAvailable = [...nonAvailable, newNonAvailable];

            // Update the state
            state.schedule.available[date] = updatedAvailable;
            state.schedule.nonAvailable[date] = updatedNonAvailable;
        },
        setEmployeeAsAvailable(state, action: PayloadAction<string, PersonWithCustomerAndDate>) {
            const payload = action.payload;
            const date = payload.date || "";
            const available = state.schedule.available[date] || [];
            const nonAvailable = state.schedule.nonAvailable[date] || [];

            // Filter out the person from the non-available array
            const updatedNonAvailable = nonAvailable.filter(
                person => person.personId !== payload.personId
            );

            const newAvailable = {
                personId: payload.personId,
                firstName: payload.firstName,
                lastName: payload.lastName,
            }

            // Assuming payload already contains a person object that should be added to Available
            const updatedAvailable = [...available, newAvailable];

            // Update the state
            state.schedule.available[date] = updatedAvailable;
            state.schedule.nonAvailable[date] = updatedNonAvailable;
        },
    },
});


export const { setAvailableAndNonAvailableEmployees, emptyAvailableAndNonAvailableEmployees, setEmployeeAsNonAvailable, setEmployeeAsAvailable } = employeePlanning.actions;
export default employeePlanning.reducer;

export const selectPlannedEmployeesForThisCustomer = (date: string, customerName: string) => {
    return (state: RootState) => {
        const nonAvailableOnDate = state.employeePlanning.schedule.nonAvailable[date] || [];
        return nonAvailableOnDate.filter(customer => customer.customerName === customerName);
    };
};
export const selectNonAvailableEmployeesForCustomer = (date: string, customerName: string) => {
    return (state: RootState) => {
        const nonAvailableOnDate = state.employeePlanning.schedule.nonAvailable[date] || [];
        return nonAvailableOnDate.filter(customer => customer.customerName !== customerName);
    };
};
export const selectAvailableEmployeesForCustomer = (date: string) => {
    return (state: RootState) => {
        const availableOnDate = state.employeePlanning.schedule.available[date] || [];
        return availableOnDate;
    };
};

export const selectEmployeesPlanningToSubmitForThisCustomerForThisWeek = (customerName: string) => {
    return (state: RootState) => {
        const nonAvailable = state.employeePlanning.schedule.nonAvailable;
        //create map from non available
        const nonAvailableMap = new Map<string, PersonWithCustomer[]>();
        for (const key in nonAvailable) {
            if (nonAvailable.hasOwnProperty(key)) {
                const element = nonAvailable[key];
                nonAvailableMap.set(key, element);
            }
        }

        //Loop over map. Check the values. If customername is equal to the one we are looking for, add to new map with key = date
        const nonAvailableMapForThisCustomer = new Map<string, PersonWithCustomer[]>();
        nonAvailableMap.forEach((value, key) => {
            const employeesForThisCustomer = value.filter(customer => customer.customerId === customerName);
            if (employeesForThisCustomer.length > 0) {
                nonAvailableMapForThisCustomer.set(key, employeesForThisCustomer);
            } else {
                nonAvailableMapForThisCustomer.set(key, []);
            }
        });


        return nonAvailableMapForThisCustomer;
    };
};

