export class CreateContactInformationDto {
    telephoneNumber: string;
    email: string;

    constructor(
        telephoneNumber: string,
        email: string
    ) {
        this.telephoneNumber = telephoneNumber;
        this.email = email;
    }

    toJson() {
        return {
            telephoneNumber: this.telephoneNumber,
            email: this.email,
        }
    }
}