import { Typography, Button, Stepper, Step, StepLabel, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import PersonalInformation from "../Components/FormSteps/PersonalInformation";
import AddressInformation from "../Components/FormSteps/AddressInformation";
import { CreateActorDto } from "../Models/dto/CreateActorDto"
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query'
import { createCoach, createCustomer, createEmployee, getCoach, getCustomer, getEmployee, getEmployeeNoDetails, updateCoach, updateCustomer, updateEmployee } from "../Services/DataService"
import { CreateContactInformationDto } from "../Models/dto/CreateContactInformationDto";
import { CreateAddressDto } from "../Models/dto/CreateAddressDto";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useTranslation } from "react-i18next";
import { ErrorElement } from '../Models/dto/ErrorElement'
import { CoachEmployee } from "../Models/CoachEmployee";
import GeneralInfo from "../Components/FormSteps/GeneralInfo";
import ContactPerson from "../Components/FormSteps/ContactPerson";
import WorkHours from "../Components/FormSteps/Workhours";
import { CreateCustomerDto } from "../Models/dto/CreateCustomerDto";
import { CreateContactPersonDto } from "../Models/dto/CreateContactPersonDto";
import { CreateWorkBreakDto, CreateWorkScheduleDto } from "../Models/dto/CreateWorkSchedule";
import { CreateJobDto } from "../Models/dto/CreateJobDto";
import { Customer } from "../Models/Customer";
import CircularProgress from '@mui/material/CircularProgress';

function AddEditPage({ edit, employee, coach, customer }: { edit: boolean, employee: boolean, coach: boolean, customer: boolean }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [postError, setError] = useState<ErrorElement | ErrorElement[]>()
    const [activeStep, setActiveStep] = useState(0);
    const steps = !customer ? [t('steps.personal'), t('steps.address')] : ["Algemene gegevens", "Adresgegevens", "Contactpersoon", "Werkuren"];
    const params = useParams();
    const [loading, setLoading] = useState(true);

    const { isLoading: retrievedCoachLoading, data: retrievedCoach } = useQuery<CoachEmployee>(
        ['coach', params.coachId],
        () => getCoach(params.coachId || ""),
        {
            staleTime: 300000,
            retry: 1,
            onSuccess: (data: any) => {
                console.log(data)
                setLoading(false)
            },
            onError: (error: any) => {
                error.response.status == 403 ? navigate("/login") : console.log(error.response.data)
            },
            enabled: edit && coach && params.coachId !== ""
        }
    );
    const { isLoading: retrievedEmployeeLoading, data: retrievedEmployee } = useQuery<CoachEmployee>(
        ['employee', params.employeeId],
        () => getEmployeeNoDetails(params.employeeId || ""),
        {
            staleTime: 300000,
            retry: 1,
            onError: (error: any) => {
                error.response.status == 403 ? navigate("/login") : console.log(error.response.data)
            },
            enabled: edit && employee && params.employeeId !== ""
        }
    );
    const { isLoading: retrievedCustomerLoading, data: retrievedCustomer } = useQuery<Customer>(
        ['customer', params.customerId],
        () => getCustomer(params.customerId || ""),
        {
            staleTime: 300000,
            retry: 1,
            onError: (error: any) => {
                error.response.status == 403 ? navigate("/login") : console.log(error.response.data)
            },
            enabled: !!edit && !!customer && params.customerId !== ""
        }
    );
    var currentActor: CoachEmployee | Customer | undefined = edit ? (coach ? retrievedCoach : (employee ? retrievedEmployee : (customer ? retrievedCustomer : undefined))) : undefined;


    const schema = yup.object().shape(
        !customer ?
            {
                lastName: yup.string().required(t('crud.exceptions.lastName')),
                firstName: yup.string().required(t('crud.exceptions.firstName')),
                email: yup.string().email(t('crud.exceptions.email')).required(t('crud.exceptions.emailRequired')),
                telephoneNumber: yup.string().required(t('crud.exceptions.telephoneNumber')),
                streetName: yup.string().required(t('crud.exceptions.streetName')),
                houseNumber: yup.string().required(t('crud.exceptions.houseNumber')),
                postalCode: yup.string().required(t('crud.exceptions.postalCode')),
                city: yup.string().required(t('crud.exceptions.city')),
            }
            :
            {
                customerName: yup.string().required(t('crud.exceptions.customerName')),
                numberOfEmployees: yup.number().required(t('crud.exceptions.numberOfEmployees')),
                jobDescription: yup.string().required(t('crud.exceptions.jobDescription')),
                streetName: yup.string().required(t('crud.exceptions.streetName')),
                houseNumber: yup.string().required(t('crud.exceptions.houseNumber')),
                postalCode: yup.string().required(t('crud.exceptions.postalCode')),
                city: yup.string().required(t('crud.exceptions.city')),
                contactFirstName: yup.string().required(t('crud.exceptions.lastName')),
                contactLastName: yup.string().required(t('crud.exceptions.firstName')),
                contactEmail: yup.string().email(t('crud.exceptions.email')).required(t('crud.exceptions.emailRequired')),
                contactTelephonenumber: yup.string().required(t('crud.exceptions.telephoneNumber')),
                startTime: yup.string().required(t('crud.exceptions.startTime')),
                endTime: yup.string().required(t('crud.exceptions.endTime')),
            }
    );

    const { handleSubmit, register, reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const onSubmit = (data:any) => {
        const id = edit ? (coach ? (params.coachId || "") : (employee ? (params.employeeId || "") : (params.customerId || ""))) : "";
        var toSubmit: any;
        if (!customer) {
            toSubmit = new CreateActorDto(id, data.firstName, data.lastName, new CreateAddressDto(data.streetName, data.houseNumber, data.postboxNumber, data.city, data.postalCode, data.country), new CreateContactInformationDto(data.telephoneNumber, data.email));
        } else {
            //It is a customer
            const breaks: CreateWorkBreakDto[] = [];
            data?.breaks?.length !== 0 && data?.breaks?.forEach((element:any) => {
                breaks.push(new CreateWorkBreakDto(element.breakId, element.startTime, element.endTime, element.paid));
            });

            toSubmit = new CreateCustomerDto(id, data.customerName,
                new CreateContactPersonDto(data.contactPersonId, data.contactFirstName, data.contactLastName,
                    new CreateContactInformationDto(data.contactTelephonenumber, data.contactEmail)),
                new CreateWorkScheduleDto(data.workScheduleId, data.startTime, data.endTime, breaks), new CreateJobDto(data.jobDescription, data.numberOfEmployees), new CreateAddressDto(data.streetName, data.houseNumber, data.postboxNumber, data.city, data.postalCode, data.country));
        }
        mutate(toSubmit.toJSON());
    };

    const { mutate, isLoading: saveLoading } = useMutation(
        edit ? (coach ? updateCoach : (employee ? updateEmployee : updateCustomer)) : (coach ? createCoach : (employee ? createEmployee : createCustomer))
        , {
            onSuccess: () => {
                (!edit) && reset();

                coach && queryClient.invalidateQueries({ queryKey: ['allCoaches'] });
                (edit && coach) && queryClient.removeQueries({ queryKey: ['coach', params.coachId] });
                (!edit && coach) && navigate("/coaches");
                (edit && coach) && navigate(`/coaches/${params.coachId}`);

                employee && queryClient.invalidateQueries({ queryKey: ['allEmployees'] });
                (edit && employee) && queryClient.removeQueries({ queryKey: ['employee', params.employeeId] });
                (!edit && employee) && navigate("/employees");
                (edit && employee) && navigate(`/employees/${params.employeeId}`);

                (customer && !edit) && navigate("/customers");
                (customer && edit) && navigate(`/customers/${params.customerId}`);
                customer && queryClient.invalidateQueries({ queryKey: ['allCustomers'] });
                (edit && customer) && queryClient.removeQueries({ queryKey: ['customer', params.customerId] });
            },
            onError: (error: any) => {
                error.response.status == 403 ? navigate('/login') : setError(error.response.data);
            }
        });

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>

            <Grid container sx={{ width: '50vw', margin: '0 auto', marginBottom: "2vh", backgroundColor: "#facd69" }}>
                <Grid item xs={12} sx={{ textAlign: "center", marginTop: "0.5vh", marginBottom: "0.5vh" }}>
                    <Typography variant="h5" component="h2">
                        {edit
                            ?
                            (coach
                                ?
                                t(`coach.update.title`, { lastName: (currentActor as CoachEmployee)?.lastName, firstName: (currentActor as CoachEmployee)?.firstName })
                                :
                                (employee
                                    ?
                                    t(`employee.update.title`, { lastName: (currentActor as CoachEmployee)?.lastName, firstName: (currentActor as CoachEmployee)?.firstName })
                                    :
                                    t(`customer.update.title`, { customerName: (currentActor as Customer)?.customerName }))
                            )
                            :
                            (coach ? t('coach.create.title') : (employee ? t('employee.create.title') : t('customer.create.title')))}
                    </Typography>
                </Grid>
            </Grid>

            <Stepper activeStep={activeStep} sx={{ width: "50%", alignSelf: "center" }}>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container textAlign="center" spacing={2}>
                    <Grid item xs={12}>
                        {(activeStep === 0 && !customer) && <PersonalInformation register={!customer ? register : null} actor={(edit) ? (currentActor as CoachEmployee) : undefined} />}
                        {(activeStep === 0 && customer) && <GeneralInfo register={register} customer={(edit) ? (currentActor as Customer) : undefined} />}

                        {activeStep === 1 && <AddressInformation register={register} coach={(edit && !customer) ? (currentActor as CoachEmployee) : undefined} customer={(edit && customer) ? (currentActor as Customer) : undefined} />}
                        {activeStep === 2 && <ContactPerson register={register} customer={(edit) ? (currentActor as Customer) : undefined} />}
                        {activeStep === 3 && <WorkHours register={register} customer={(edit) ? (currentActor as Customer) : undefined} />}

                        {Object.values(errors).some((error) => error) && (
                            <>
                                {Object.entries(errors).map(([key, value]) => (
                                    value && (
                                        <div key={key} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <div style={{ display: 'flex', textAlign: "left", alignItems: 'center', width: "25vw" }}>
                                                <ErrorOutlineIcon style={{ fill: '#FF2D00' }} />
                                                <p style={{ marginLeft: '2px' }}>{value?.message}</p>
                                            </div>
                                        </div>
                                    )
                                ))}
                            </>
                        )}
                        {postError && (
                            Array.isArray(postError) ? (
                                postError.map((err, index) => (
                                    <div key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                                        <ErrorOutlineIcon style={{ fill: '#FF2D00' }} />
                                        <p style={{ marginLeft: '2px' }}>{t(`exceptions.${err.errorCode}`, { field: t(`coach.create.fields.${err.field}`), input: err.input })}</p>
                                    </div>
                                ))
                            ) : (
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                                    <ErrorOutlineIcon style={{ fill: '#FF2D00' }} />
                                    <p style={{ marginLeft: '2px' }}>{t(`exceptions.${postError.errorCode}`, { field: t(`coach.create.fields.${postError.field}`), input: postError.input ? postError.input : "Deze persoon/klant" })}</p>
                                </div>
                            )
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        {(activeStep > 0 && activeStep <= steps.length) && <Button variant="contained" onClick={handleBack} sx={{ mr: "2%", width: "15%" }}> {t("steps.previous")} </Button>}
                        {activeStep == steps.length - 1 && <Button variant="contained" type="submit" sx={{ width: "15%" }}>{t("steps.save")}</Button>}
                        {activeStep < steps.length - 1 && <Button variant="contained" onClick={handleNext} sx={{ width: "15%" }}> {t("steps.next")} </Button>}
                    </Grid>
                </Grid>
            </form>
        </div >
    );
}

export default AddEditPage;
