import React from "react";
import { Box, Typography, TextField, Button, FormControlLabel, Checkbox, Grid, Link } from "@mui/material";
import { useForm } from "react-hook-form";
import { useMutation } from '@tanstack/react-query'
import { authenticate } from '../../Services/DataService';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useState } from "react";
import { ErrorElement } from '../../Models/dto/ErrorElement'
import { authenticateState } from '../../Reducers/Auth';
import { useSelector, TypedUseSelectorHook, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Me } from "../../Reducers/Models/Me";
import { CircularProgress } from "@mui/material";

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [postError, setError] = useState<ErrorElement>()
    const onSubmit = async (data:any) => {
        mutate(data);
    };

    const { handleSubmit, register, reset, formState: { errors } } = useForm({
    });

    const { mutate, isLoading } = useMutation(
        authenticate
        , {
            onSuccess: (data: Me) => {
                dispatch(authenticateState(data));
                reset();
                if (data.passwordChangeRequired) {
                    navigate("/change_password")
                } else {
                    navigate("/")
                }
            },
            onError: (error: any) => {
                error.response.status == 403 ? navigate('/login') : setError(error.response.data);
            }
        });

    if (isLoading) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '80vh'
                }}
            >
                <CircularProgress />
            </div>
        );
    }

    return (
        <Box
            sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Typography component="h1" variant="h5">
                Aanmelden
            </Typography>
            <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Emailadres"
                    autoComplete="email"
                    autoFocus
                    {...register("email")}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Wachtwoord"
                    type="password"
                    autoComplete="current-password"
                    {...register("password")}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Meld aan
                </Button>
                <Grid container>
                    <Grid item xs>
                        {/* <Link href="#" variant="body2">*/}
                    </Grid>
                </Grid>
                {postError &&
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{ display: 'flex', textAlign: "left", alignItems: 'center', width: "25vw" }}>
                            <ErrorOutlineIcon style={{ fill: '#FF2D00' }} />
                            <p style={{ marginLeft: '2px' }}>{postError.errorCode}</p>
                        </div>
                    </div>
                }
            </Box>
        </Box>
    );
};

export default Login;
