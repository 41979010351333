import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Button } from '@mui/material';

const NotFound = () => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant="h1" sx={{ fontWeight: 'bold' }}>404</Typography>
            <Typography variant="h2" sx={{ fontWeight: 'bold' }}>Page not found</Typography>
            <Button variant="contained" component={Link} to="/" sx={{ marginTop: '1rem' }}>Go back to home</Button>
        </Box>
    );
};

export default NotFound;