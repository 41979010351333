import React from "react";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { styled } from '@mui/material/styles';
import 'dayjs/locale/nl-be';

dayjs.extend(isBetweenPlugin);

interface CustomPickerDayProps extends PickersDayProps<Dayjs> {
    isSelected: boolean;
    isHovered: boolean;
}

const CustomPickersDay = styled(PickersDay, { shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isHovered', })<CustomPickerDayProps>(({ theme, isSelected, isHovered, day }) => ({
    borderRadius: 0,
    ...(isSelected && {
        backgroundColor: "#fe5722",
        color: "white",
        '&:hover, &:focus': {
            backgroundColor: "#fe5722",
            color: "white",
        },
    }),
    ...(isHovered && {
        backgroundColor: "#fe8964",
        color: "white",
        '&:hover, &:focus': {
            backgroundColor: "#fe8964",
            color: "white",
        }
    }),
    ...(day.day() === 1 && {
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
    }),
    ...(day.day() === 5 && {
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
    }),
    color: isSelected || isHovered ? "white" : "#fe5722",
})) as React.ComponentType<CustomPickerDayProps>;

const isInSameWeek = (dayA: Dayjs, dayB: Dayjs | null | undefined) => {
    if (dayB === null) {
        return false;
    }
    const sameWeek = dayA.isSame(dayB, 'week');
    const isWeekday = dayA.day() >= 1 && dayA.day() <= 5;
    return sameWeek && isWeekday;
};

function Day(
    props: PickersDayProps<Dayjs> & {
        selectedDay?: Dayjs | null;
        hoveredDay?: Dayjs | null;
    },
) {
    const { day, selectedDay, hoveredDay, ...other } = props;
    const isWeekend = day.day() === 0 || day.day() === 6;

    return (
        <CustomPickersDay
            {...other}
            day={day}
            sx={{ px: 2.5 }}
            disableMargin
            selected={false}
            disabled={isWeekend}
            isSelected={isInSameWeek(day, selectedDay)}
            isHovered={isInSameWeek(day, hoveredDay)}

        />
    );
}

function WeekPicker({ selectedWeek, value, onValueChange }: { selectedWeek: (any) => void, value: Dayjs | null, onValueChange: (newValue: Dayjs | null) => void }) {
    const [hoveredDay, setHoveredDay] = React.useState<Dayjs | null>(null);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nl-be">
            <DateCalendar
                value={value}
                onChange={(newValue) => {
                    sessionStorage.setItem('selectedWeek', newValue?.startOf('week').format('DD-MM-YYYY') || '');
                    onValueChange(newValue);
                    selectedWeek(newValue?.startOf('week').format('DD-MM-YYYY'));
                }}
                showDaysOutsideCurrentMonth
                displayWeekNumber
                slots={{ day: Day }}
                slotProps={{
                    day: (ownerState) =>
                    ({
                        selectedDay: value,
                        hoveredDay,
                        onPointerEnter: () => setHoveredDay(ownerState.day),
                        onPointerLeave: () => setHoveredDay(null),
                    } as any),
                }}
                sx={{ color: "#fe5722" }}
            />
        </LocalizationProvider>
    );
}

export default WeekPicker;
