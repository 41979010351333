
import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useForm } from 'react-hook-form';
import { CoachEmployee } from '../Models/CoachEmployee';
import { JobAssignment } from '../Models/JobAssignment';
import { createDayReport } from '../Services/DataService';
import { ErrorElement } from '../Models/dto/ErrorElement';
import { CircularProgress, Grid, TextField, Button } from '@mui/material';
import { useTranslation } from "react-i18next";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";

function JobAssignmentForm({ jobAssignment, onClose, employeeId }: { jobAssignment: JobAssignment, onClose: () => void, employeeId: string }) {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const [postError, setError] = React.useState<ErrorElement | ErrorElement[]>()
    const navigate = useNavigate();

    const schema = yup.object().shape(
        {
            startTimeEmployee: yup.string()
                .matches(/^([01]?[0-9]|2[0-3]):([0-5]?[0-9])$/, t('crud.exceptions.wrong_time_format'))
                .required(t('crud.exceptions.starttime')),
            endTimeEmployee: yup.string()
                .matches(/^([01]?[0-9]|2[0-3]):([0-5]?[0-9])$/, t('crud.exceptions.wrong_time_format'))
                .required(t('crud.exceptions.endtime')),
            modeOfTransport: yup.string().typeError(t('crud.exceptions.type_text')).nullable().notRequired(),
            comments: yup.string().typeError(t('crud.exceptions.type_text')).nullable().notRequired(),
            notProducedPaidHours: yup.number().typeError(t('crud.exceptions.type_number')).nullable().notRequired(),
            notProducedPaidHoursExplanation: yup.string().typeError(t('crud.exceptions.type_text')).nullable().notRequired(),
            notProducedNotPaidHours: yup.number().typeError(t('crud.exceptions.type_number')).nullable().notRequired(),
            notProducedNotPaidHoursExplanation: yup.string().typeError(t('crud.exceptions.type_text')).nullable().notRequired(),
            jobAssignmentId: yup.string().nullable().notRequired()
        }
    );

    const { handleSubmit, register, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = (data) => {
        queryClient.removeQueries(["employee", (data as CoachEmployee).personId])
        mutate({
            reportData: {
                comments: data.comments,
                startTimeEmployee: data.startTimeEmployee,
                endTimeEmployee: data.endTimeEmployee,
                modeOfTransport: data.modeOfTransport,
                notProducedNotPaid: {
                    numberOfHours: data.notProducedNotPaidHours,
                    reason: data.notProducedNotPaidHoursExplanation
                },
                notProducedPaid: {
                    numberOfHours: data.notProducedPaidHours,
                    reason: data.notProducedPaidHoursExplanation
                }
            },
            employeeId: (data as CoachEmployee).personId,
            jobAssignmentId: data.jobAssignmentId
        });

    };

    const { mutate, isLoading: createDayReportLoading } = useMutation(
        (data: any) => createDayReport(data)
        , {
            onSuccess: () => {

            },
            onError: (error: any) => {
                error.response.status == 403 ? navigate('/login') : setError(error.response.data);
            }
        });

    if (createDayReportLoading) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '80vh'
                }}
            >
                <CircularProgress />
            </div>
        );
    }

    return (
        <Grid container sx={{ marginTop: "2vh", marginBottom: "2vh", display: "flex", flexDirection: "column" }} >
            <Grid item style={{ width: "70vw", alignSelf: "center" }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {getTextField(t('dayreport_form.start_time'), (jobAssignment as JobAssignment).dayReport && (jobAssignment as JobAssignment).dayReport.startTimeEmployee, { ...register("startTimeEmployee") },
                        1.5, "100%", "32%", "2%")}
                    {getTextField(t('dayreport_form.end_time'), (jobAssignment as JobAssignment).dayReport && (jobAssignment as JobAssignment).dayReport.endTimeEmployee, { ...register("endTimeEmployee") },
                        1.5, "100%", "32%", "2%")}
                    {getTextField(t('dayreport_form.transport_type'), (jobAssignment as JobAssignment).dayReport && (jobAssignment as JobAssignment).dayReport.modeOfTransport, { ...register("modeOfTransport") },
                        1.5, "100%", "32%")}
                    {getTextField(t('dayreport_form.comments'), (jobAssignment as JobAssignment).dayReport && (jobAssignment as JobAssignment).dayReport.comments, { ...register("comments") },
                        1.5, "100%", "100%", "0%", "20%")}
                    {getTextField(t('dayreport_form.not_produced_paid_hours'), (jobAssignment as JobAssignment).dayReport?.notProducedPaid ? (jobAssignment as JobAssignment).dayReport.notProducedPaid.numberOfHours : 0,
                        { ...register("notProducedPaidHours") }, 1.5, "100%", "25%", "2%")}
                    {getTextField(t('dayreport_form.explanation'), (jobAssignment as JobAssignment).dayReport && (jobAssignment as JobAssignment).dayReport.notProducedPaid.reason,
                        { ...register("notProducedPaidHoursExplanation") }, 1.5, "100%", "73%")}
                    {getTextField(t('dayreport_form.not_produces_not_paid_hours'), (jobAssignment as JobAssignment).dayReport?.notProducedNotPaid ? (jobAssignment as JobAssignment).dayReport.notProducedNotPaid.numberOfHours : 0,
                        { ...register("notProducedNotPaidHours") }, 1.5, "100%", "25%", "2%")}
                    {getTextField(t('dayreport_form.explanation'), (jobAssignment as JobAssignment).dayReport && (jobAssignment as JobAssignment).dayReport.notProducedNotPaid.reason,
                        { ...register("notProducedNotPaidHoursExplanation") }, 1.5, "100%", "73%")}
                    <input
                        type="hidden"
                        defaultValue={(jobAssignment as JobAssignment) && (jobAssignment as JobAssignment).jobAssignmentId}
                        {...register("jobAssignmentId")}
                    />
                    <Button variant="contained" type="submit" sx={{ width: { xs: "100%", sm: "40%" } }}>{t('steps.save')}</Button>
                    <Button variant="contained" sx={{ width: { xs: "100%", sm: "40%" }, mt: { xs: 2, sm: 0 }, marginLeft: { sm: "20%" } }} onClick={() => { onClose() }}>{t('steps.close')}</Button>
                </form>
                {postError && (
                    Array.isArray(postError) ? (
                        postError.map((err, index) => (
                            <div key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                                <ErrorOutlineIcon style={{ fill: '#FF2D00' }} />
                                <p style={{ marginLeft: '2px' }}>{t(`exceptions.${err.errorCode}`, { field: t(`coach.create.fields.${err.field}`), input: err.input })}</p>
                            </div>
                        ))
                    ) : (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                            <ErrorOutlineIcon style={{ fill: '#FF2D00' }} />
                            <p style={{ marginLeft: '2px' }}>{t(`exceptions.${postError.errorCode}`, { field: t(`coach.create.fields.${postError.field}`), input: postError.input ? postError.input : "Deze persoon/klant" })}</p>
                        </div>
                    )
                )}
                {Object.values(errors).some((error) => error) && (
                    <>
                        {Object.entries(errors).map(([key, value]) => (
                            value && (
                                <div key={key} style={{ display: 'flex' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <ErrorOutlineIcon style={{ fill: '#FF2D00' }} />
                                        <p style={{ marginLeft: '2px' }}>{value?.message}</p>
                                    </div>
                                </div>
                            )
                        ))}
                    </>
                )}
            </Grid>
        </Grid>
    );
}

export default JobAssignmentForm;

function getTextField(label: string, defaultValue: string | number, register: any, mb: number = 1.5, widthXs: string = "100%", widthSm: string = "32%", mr?: string, height?: string) {
    return (
        <TextField
            label={label}
            defaultValue={defaultValue}
            variant="outlined"
            sx={{ mb: mb, width: { xs: widthXs, sm: widthSm }, mr: mr ? mr : "0%", height: height }}
            {...register}
        />
    );
}
