import {
    getAllCoaches,
    deleteCoach,
    getAllEmployees,
    deleteEmployee,
    getAllCustomers,
    deleteCustomer
} from "../Services/DataService"
import {
    CircularProgress, Table, TableBody, TableCell, TableHead, TableContainer, TableRow, Grid,
    Paper, Link, Typography, Button
} from '@mui/material/';
import {useMutation, useQueryClient, useQuery} from '@tanstack/react-query'
import {Link as RouterLink, useNavigate} from "react-router-dom";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import React from 'react';
import {useTranslation} from "react-i18next";
import {CoachEmployee} from "../Models/CoachEmployee";
import {Customer} from "../Models/Customer";
import {ErrorElement} from "../Models/dto/ErrorElement";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {Me} from "../Reducers/Models/Me";

function OverviewPage({employee, coach, customer, user}: {
    employee: boolean,
    coach: boolean,
    customer: boolean,
    user: Me | null
}) {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [error, setError] = React.useState<ErrorElement | ErrorElement[]>()

    type QueryReturnType = Customer[] | CoachEmployee[];

    const {queryKey, queryFn, staleTime} = coach
        ? {
            queryKey: ['allCoaches'],
            queryFn: getAllCoaches,
            staleTime: 300000
        }
        :
        (employee ?
            {
                queryKey: ['allEmployees'],
                queryFn: getAllEmployees,
                staleTime: 300000,
            }
            :
            {
                queryKey: ['allCustomers'],
                queryFn: getAllCustomers,
                staleTime: 300000
            })
    ;

    const { isLoading, data } = useQuery<QueryReturnType>(queryKey, queryFn, {
        staleTime, retry: 0, onError: (error: any) => {
            error.response.status == 403 ? navigate("/login") : setError(error.response.data)
        }
    });

    const deleteCurrentActor = useMutation(coach ? deleteCoach : (employee ? deleteEmployee : deleteCustomer), {
        onSuccess: () => {
            coach ? queryClient.invalidateQueries({queryKey: ['allCoaches']}) : (employee ? queryClient.invalidateQueries({queryKey: ['allEmployees']}) : queryClient.invalidateQueries({queryKey: ['allCustomers']}));
        },
        onError: (error: any) => {
            error.response.status == 403 ? navigate('/login') : setError(error.response.data);
        }
    });

    if (isLoading || deleteCurrentActor.isLoading) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '80vh'
                }}
            >
                <CircularProgress/>
            </div>
        );

    }

    if (!data || data.length === 0) {
        return (
            <Grid container sx={{width: '80vw', margin: '0 auto', marginBottom: "1vh", backgroundColor: "#facd69"}}>
                <Grid item xs={9}>
                    <Typography variant='h4' sx={{
                        textAlign: "center",
                        fontSize: {
                            xs: '20px',
                            sm: '30px',
                            md: '35px'
                        }
                    }}>{coach ? t('coach.coaches') :
                        (employee ? t('employee.employees') : t('customer.customers'))}</Typography>
                    <p>{coach ? t('coach.notFoundAll') : (employee ? t('employee.notFoundAll') : t('customer.notFoundAll'))}</p>

                </Grid>
                <Grid item xs={3} sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    {user?.role == "ROLE_ADMIN" &&
                        <RouterLink to={coach ? "/coaches/add" : (employee ? "/employees/add" : "/customers/add")}>
                            <Button
                                sx={{
                                    backgroundColor: 'transparent',
                                    color: 'black',
                                    '&:hover': {
                                        backgroundColor: 'grey',
                                        color: '#facd69',
                                    }
                                }}>
                                <AddCircleIcon/>
                            </Button>
                        </RouterLink>
                    }
                </Grid>
            </Grid>
        );
    }

    return (
        <>
            <Grid container sx={{width: '80vw', margin: '0 auto', marginBottom: "1vh", backgroundColor: "#facd69"}}>
                <Grid item xs={9}>
                    <Typography variant='h4' sx={{
                        textAlign: "center",
                        fontSize: {
                            xs: '20px',
                            sm: '30px',
                            md: '35px'
                        }
                    }}>{coach ? t('coach.coaches') :
                        (employee ? t('employee.employees') : t('customer.customers'))}</Typography>
                </Grid>
                <Grid item xs={3} sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    {user?.role == "ROLE_ADMIN" &&
                        <RouterLink to={coach ? "/coaches/add" : (employee ? "/employees/add" : "/customers/add")}>
                            <Button
                                sx={{
                                    backgroundColor: 'transparent',
                                    color: 'black',
                                    '&:hover': {
                                        backgroundColor: 'grey',
                                        color: '#facd69',
                                    }
                                }}>
                                <AddCircleIcon/>
                            </Button>
                        </RouterLink>
                    }
                </Grid>
            </Grid>


            <div>
                <div style={{width: '80vw', margin: '0 auto'}}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                {!customer ?
                                    <TableRow>
                                        <TableCell width="20%">{t('crud.fields.lastName')}</TableCell>
                                        <TableCell width="20%">{t('crud.fields.firstName')}</TableCell>
                                        <TableCell width="20%">{t('crud.fields.telephoneNumber')}</TableCell>
                                        <TableCell width="20%">{t('crud.fields.email')}</TableCell>
                                        <TableCell width="20%">{t('crud.actions')}</TableCell>
                                    </TableRow>
                                    :
                                    <TableRow>
                                        <TableCell width="20%">{t('crud.fields.customerName')}</TableCell>
                                        <TableCell width="20%">{t('crud.fields.address')}</TableCell>
                                        <TableCell width="20%">{t('crud.fields.telephoneNumber')}</TableCell>
                                        <TableCell width="20%">{t('crud.fields.email')}</TableCell>
                                        <TableCell width="20%">{t('crud.actions')}</TableCell>
                                    </TableRow>
                                }

                            </TableHead>
                            <TableBody>
                                {!customer ?
                                    data?.map((actor) => (
                                        <TableRow
                                            key={(actor as CoachEmployee).personId}
                                        >
                                            {!customer &&
                                                <>
                                                    <TableCell width="20%">{(actor as CoachEmployee).lastName}</TableCell>
                                                    <TableCell width="20%">{(actor as CoachEmployee).firstName}</TableCell>
                                                    <TableCell
                                                        width="20%">{(actor as CoachEmployee).contactInformation?.telephoneNumber}</TableCell>
                                                    <TableCell width="20%">{(actor as CoachEmployee).contactInformation?.email}</TableCell>
                                                    <TableCell width="20%">
                                                        <RouterLink
                                                            to={coach ? `/coaches/${(actor as CoachEmployee).personId?.toString()}` : `/employees/${(actor as CoachEmployee).personId?.toString()}`}>
                                                            <Link sx={{
                                                                cursor: "pointer",
                                                                float: 'left'
                                                            }}>{t('crud.details')}</Link>
                                                        </RouterLink>
                                                        {user?.role == "ROLE_ADMIN" &&
                                                            <>
                                                                <Link sx={{
                                                                    cursor: "pointer",
                                                                    float: 'left',
                                                                    marginLeft: "10px"
                                                                }} onClick={() => {
                                                                    coach ? navigate(`/coaches/${(actor as CoachEmployee).personId?.toString()}/edit`) : navigate(`/employees/${(actor as CoachEmployee).personId?.toString()}/edit`)
                                                                }}>{t('crud.edit')}</Link>
                                                                <Link sx={{
                                                                    cursor: "pointer",
                                                                    float: 'left',
                                                                    marginLeft: "10px"
                                                                }}
                                                                      onClick={() => deleteCurrentActor.mutate((actor as CoachEmployee).personId)}>{t('crud.delete')}</Link>
                                                            </>
                                                        }
                                                    </TableCell>
                                                </>
                                            }

                                        </TableRow>
                                    ))
                                    :
                                    data?.map((actor) => (
                                        <TableRow
                                            key={(actor as Customer).customerId}
                                        >
                                            {customer &&
                                                <>
                                                    <TableCell width="8vw">{(actor as Customer).customerName}</TableCell>
                                                    <TableCell
                                                        width="8vw">{`${(actor as Customer).address.streetName} ${(actor as Customer).address.houseNumber}, ${(actor as Customer).address.postalCode} ${(actor as Customer).address.city}`}</TableCell>
                                                    <TableCell
                                                        width="8vw">{(actor as Customer).contactPerson.contactInformation?.telephoneNumber}</TableCell>
                                                    <TableCell
                                                        width="8vw">{(actor as Customer).contactPerson.contactInformation.email}</TableCell>
                                                    <TableCell width="8vw">
                                                        <RouterLink to={`/customers/${(actor as Customer).customerId?.toString()}`}>
                                                            <Link sx={{
                                                                cursor: "pointer",
                                                                float: 'left'
                                                            }}>{t('crud.details')}</Link>
                                                        </RouterLink>
                                                        {user?.role == "ROLE_ADMIN" &&
                                                            <>
                                                                <Link sx={{
                                                                    cursor: "pointer",
                                                                    float: 'left',
                                                                    marginLeft: "10px"
                                                                }} onClick={() => {
                                                                    navigate(`/customers/${(actor as Customer).customerId?.toString()}/edit`)
                                                                }}>{t('crud.edit')}</Link>
                                                                <Link sx={{
                                                                    cursor: "pointer",
                                                                    float: 'left',
                                                                    marginLeft: "10px"
                                                                }}
                                                                      onClick={() => deleteCurrentActor.mutate((actor as Customer).customerId)}>{t('crud.delete')}</Link>
                                                            </>
                                                        }
                                                    </TableCell>
                                                </>
                                            }

                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {error && (
                        Array.isArray(error) ? (
                            error.map((err, index) => (
                                <div key={index}
                                     style={{display: 'flex', alignItems: 'center', justifyContent: "center"}}>
                                    <ErrorOutlineIcon style={{fill: '#FF2D00'}}/>
                                    <p style={{marginLeft: '2px'}}>{t(`exceptions.${err.errorCode}`, {
                                        field: t(`coach.create.fields.${err.field}`),
                                        input: err.input ? err.input : "Deze persoon"
                                    })}</p>
                                </div>
                            ))
                        ) : (
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: "center"}}>
                                <ErrorOutlineIcon style={{fill: '#FF2D00'}}/>
                                <p style={{marginLeft: '2px'}}>{t(`exceptions.${error.errorCode}`, {
                                    field: t(`coach.create.fields.${error.field}`),
                                    input: error.input ? error.input : "Deze persoon"
                                })}</p>
                            </div>
                        )
                    )}
                </div>
            </div>
        </>
    )
}

export default OverviewPage;
